import React, { useContext, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderStyles, renderMetaDesc } from '../../helpers/api';
import { renderPostFields } from '../../helpers/render';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import LocationBox from '../../components/location/LocationBox/LocationBox';
import Header from '../../components/header/Header/HeaderBrand';
import ScrollTopButton from '../../components/nav/ScrollTopButton/ScrollTopButton';
import Footer from '../../components/footer/Footer/Footer';
import FooterNav from '../FooterNav/FooterNav';
import { RouterContext } from '../../stores/RouterContext';

/**
 * component represents single post of custom post types (brand)
 */
const Brand = inject(
  'brandStore',
  'windowStore'
)(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const location = routerContext.location;
    const slug = routerContext.slug;
    // if no location slug is provided, get the subdomain
    const locationSlug = routerContext.locationSlug;
    // generate unique post key with location and slug
    const postKey = locationSlug + '-' + slug;

    const isFetched = props.brandStore.isFetched.get(postKey);
    const post = props.brandStore.data.posts[postKey];
    const scrollToTop = !location.hash && (!location.state || !location.state.scrollTo);

    const renderHead = () => {
      if (props.brandStore.isFetched.get(postKey)) {
        const post = props.brandStore.data.posts[postKey];
        const city = post.location.city;
        const locationName = post.location.name;
        let title = post.title;
        if (post.title) {
          // example brand
          // medi. ich fühl mich besser in Berlin - Hempel GesundheitsPartner Kreuzberg
          title = post.title + ' in ' + city + ' - ' + locationName;
        }
        return renderTitle(title, false);
      } else {
        return null;
      }
    };

    const handleRenderMetaDesc = () => {
      if (props.brandStore.isFetched.get(postKey)) {
        const post = props.brandStore.data.posts[postKey];
        let desc = post.meta_desc;
        const title = post.location.title;
        desc = desc ? desc + ' | ' + title : title;
        return renderMetaDesc(desc);
      } else {
        return null;
      }
    };

    const handleRenderStyles = () => {
      let style = '';
      const isFetched = props.brandStore.isFetched.get(postKey);
      const post = props.brandStore.data.posts[postKey];
      //brand
      if (isFetched && post && post.custom_styles) {
        style += post.custom_styles + ' ';
      }

      if (style) {
        return renderStyles(style);
      } else {
        return null;
      }
    };

    useEffect(() => {
      if (props.apiParams && slug && slug.length && locationSlug && locationSlug.length && postKey && postKey.length) {
        props.brandStore.fetchIfNeeded(postKey, slug, locationSlug);
        document.body.classList.add('page');
        if (isFetched) {
          document.body.classList.add('single-sanoa_brand');
        }

        return () => {
          document.body.classList.remove('page');
          if (isFetched) {
            document.body.classList.remove('single-sanoa_brand');
          }
        };
      }
    }, [postKey, slug, locationSlug, props.apiParams, props.brandStore, isFetched]);

    if ((isFetched && false === post) || (props.brandStore.isFetchedAll && !post)) {
      return <NoMatch />;
    }

    if (isFetched) {
      if (!post.location) {
        return <NoMatch noLocation={true} />;
      }
    }

    return (
      <div className="ida-page">
        {isFetched ? (
          <div className="ida-content " data-test={post.post_type}>
            {scrollToTop && <ScrollToTop onHistoryPush={true} />}
            <ScrollToHash />
            {props.windowStore.isGreaterEqualThan('xl') && (
              <div className="ida-location-box__sticky-container">
                <div className="row container">
                  <div className="offset-8 col-4">
                    {
                      // desktop location box, see HeaderBrand.js for mobile
                      post.location && <LocationBox headerTitle={post.location_box_title} post={post} />
                    }
                  </div>
                </div>
              </div>
            )}
            {renderHead()}
            {handleRenderStyles()}
            {handleRenderMetaDesc()}
            <Header post={post} />
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-8 -post-content">
                  {renderPostFields(post)}
                  <FooterNav navigation={post.navigation} />
                </div>
              </div>
            </div>
            <ScrollTopButton />
            {post.location?.member && <Footer post={post} />}
          </div>
        ) : (
          <LoadingAnimation />
        )}
      </div>
    );
  })
);

export default Brand;
