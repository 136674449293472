import React from 'react';
import { Img as ImgCmp } from 'react-image';

const Img = (props) => {
  if (!props.src) {
    return null;
  }
  const prefix =
    typeof process.env.REACT_APP_IMG_PROXY_URL !== 'undefined' ? process.env.REACT_APP_IMG_PROXY_URL : false;
  let src;

  const concatSrcStr = (srcStr) => {
    const pattern = /^https?|^\/\//i;
    if (!pattern.test(srcStr)) {
      if (srcStr.charAt(0) === '/') srcStr = srcStr.substr(1);
      return prefix + srcStr;
    } else {
      return srcStr;
    }
  };

  const prepareSrc = () => {
    if (!prefix) {
      src = props.src;
    } else {
      if (Array.isArray(props.src)) {
        src = props.src.map((srcStr) => {
          return concatSrcStr(srcStr);
        });
      } else {
        src = concatSrcStr(props.src);
      }
    }
  };
  prepareSrc();
  return <ImgCmp {...props} src={src} />;
};

export default Img;
