import React from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';

/**
 * youtube iframe component with blocker for iframe replacement in react html parser
 * for wp-embeded youtube videos
 * @see renderText
 * @see parserOptions
 */

const YoutubeIFrame = inject('blockDataStore')(
  observer((props) => {
    const allowYoutube = props.blockDataStore.allowYoutube;
    if (!allowYoutube) {
      return (
        <BlockButton
          provider="youtube"
          thumbnail={props.thumbnail}
          onClick={props.blockDataStore.onClickEnableOption}
        />
      );
    } else {
      const { title, ...attribs } = props.attribs;
      return <iframe title={title} {...attribs}></iframe>;
    }
  })
);

export default YoutubeIFrame;
