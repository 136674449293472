import React from 'react';
import { inject, observer } from 'mobx-react';
import { Navigation, Virtual, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { renderText } from '../../../helpers/api';
import UniLink from '../../elements/UniLink/UniLink';

/**
 * Google Posts swiper slider displays the Google Posts of current location
 * @see https://swiperjs.com/swiper-api
 */

const ACFSliderGooglePosts = inject('windowStore')(
  observer((props) => {
    const windowStore = props.windowStore;
    const sliderItems = props.content.items;
    const itemWidth = windowStore.isGreaterEqualThan('xl') ? 400 : 340;
    let isSlider = false;
    if (sliderItems) {
      isSlider =
        (windowStore.isSmallerThan('sm') && 1 < sliderItems.length) ||
        (windowStore.isSmallerThan('md') && 2 < sliderItems.length) ||
        (windowStore.isGreaterEqualThan('md') && 3 < sliderItems.length);
    }

    const getTruncatedText = (text) => {
      let result = text;
      if (windowStore.isGreaterEqualThan('xl')) {
        if (text.length > 377) {
          result = text.substring(0, 376) + '...';
        }
      } else {
        if (text.length > 200) {
          result = text.substring(0, 199) + '...';
        }
      }
      return renderText(result);
    };

    const SliderItem = ({ item, showImages }) => {
      return (
        <div className="-item-wrapper -shadow">
          {item.link && <UniLink className="mink-slider-sm__item-link" href={item.link} target="_blank" />}
          {showImages ? (
            <div className="-wrapper -with-img">
              <div className="aspect-content">
                <div className="-img">
                  {item.img ? (
                    <>
                      <img width={'auto'} height={'auto'} src={item.img} alt="Google Beitragsbild" />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className={showImages ? '-text-wrapper' : '-text-wrapper -no-img'}>
            <div className="-text">{getTruncatedText(item.text)}</div>
          </div>
          {item.link ? (
            <div className="-link-wrapper">
              <div className="-link">
                <UniLink href={item.link} target="_blank">
                  Mehr
                </UniLink>
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    if (isSlider) {
      return (
        <Swiper
          slidesOffsetBefore={5}
          slidesOffsetAfter={5}
          width={itemWidth}
          spaceBetween={16}
          freeMode
          centerInsufficientSlides={false}
          navigation={sliderItems.length <= 2 ? false : true}
          modules={[FreeMode, Navigation, Virtual]}
          className="mink-slider-sm -google-posts"
          virtual={{ enabled: true, addSlidesAfter: 5, addSlidesBefore: 5 }}
        >
          {sliderItems &&
            sliderItems.map((item, i) => {
              return '' !== item.text && item.display ? (
                <SwiperSlide virtualIndex={i} key={i + '_' + item.text} className="mink-slider-sm__item">
                  <SliderItem item={item} showImages={props.content.show_images} />
                </SwiperSlide>
              ) : null;
            })}
        </Swiper>
      );
    } else {
      return (
        <div className="mink-slider-sm mink-slider-sm-inactive -google-posts">
          {sliderItems &&
            sliderItems.map((item, i) => {
              return '' !== item.text ? (
                <div key={i + '_' + item.text} className="mink-slider-sm__item" style={{ width: itemWidth - 6 }}>
                  <SliderItem item={item} showImages={props.content.show_images} />
                </div>
              ) : null;
            })}
        </div>
      );
    }
  })
);

export default ACFSliderGooglePosts;
