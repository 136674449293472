import React, { useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderStyles, renderMetaDesc } from '../../helpers/api';
import { renderPostFields } from '../../helpers/render';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import LocationBox from '../../components/location/LocationBox/LocationBox';
import Header from '../../components/header/Header/HeaderLocation';
import Footer from '../../components/footer/Footer/Footer';
import FooterNav from '../FooterNav/FooterNav';
import ScrollTopButton from '../../components/nav/ScrollTopButton/ScrollTopButton';
import { RouterContext } from '../../stores/RouterContext';

/**
 * Displays the location page
 */
const Location = inject(
  'locationsStore',
  'windowStore'
)(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const location = routerContext.location;
    const locationSlug = routerContext.locationSlug;

    const isFetched = props.locationsStore.isFetched.get(locationSlug);
    const post = props.locationsStore.data.posts[locationSlug];
    const scrollToTop = !location.hash && (!location.state || !location.state.scrollTo);
    const renderHead = () => {
      if (props.locationsStore.isFetched.get(locationSlug)) {
        const post = props.locationsStore.data.posts[locationSlug];
        const title = post.location_box_title;
        return renderTitle(title + ': ' + post.location.name, false);
      } else {
        return null;
      }
    };

    const handleRenderMetaDesc = () => {
      const store = props.locationsStore;
      if (
        store.isFetched.get(locationSlug) &&
        store.data.posts[locationSlug].location &&
        store.data.posts[locationSlug].location.meta_desc
      ) {
        const post = store.data.posts[locationSlug];
        const desc = post.location.meta_desc;

        return renderMetaDesc(desc);
      } else {
        return null;
      }
    };

    const handleRenderStyles = () => {
      if (props.locationsStore.isFetched.get(locationSlug) && props.locationsStore.data.posts[locationSlug].partner) {
        const partner = props.locationsStore.data.posts[locationSlug].partner;
        if (partner.custom_styles) {
          return renderStyles(partner.custom_styles);
        } else {
          return null;
        }
      } else {
        return null;
      }
    };

    useEffect(() => {
      props.locationsStore.fetchIfNeeded(locationSlug);
      document.body.classList.add('page');

      return () => document.body.classList.remove('page');
    }, [locationSlug, props.locationsStore]);

    if (isFetched && (false === post || !post?.location)) {
      return <NoMatch noLocation={true} />;
    }

    return (
      <div className="ida-page">
        {isFetched && post.location ? (
          <div className="ida-content">
            {scrollToTop && <ScrollToTop onHistoryPush={true} />}
            <ScrollToHash />
            {props.windowStore.isGreaterEqualThan('xl') && (
              <div className="ida-location-box__sticky-container">
                <div className="row container">
                  <div className="offset-8 col-4">
                    {
                      // desktop location box, see HeaderLocation.js for mobile
                      post.location && <LocationBox headerTitle={post.location_box_title} post={post} />
                    }
                  </div>
                </div>
              </div>
            )}
            {renderHead()}
            {handleRenderStyles()}
            {handleRenderMetaDesc()}
            <Header post={post} />
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-8 -post-content">
                  {renderPostFields(post)}
                  <FooterNav navigation={post.navigation} />
                </div>
              </div>
            </div>
            <ScrollTopButton />
            {post.location?.member && <Footer post={post} />}
          </div>
        ) : (
          <LoadingAnimation />
        )}
      </div>
    );
  })
);

export default Location;
