import React, { useContext } from 'react';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { observer, inject } from 'mobx-react';
import { RouterContext } from '../../stores/RouterContext';

const FooterNav = inject('navigationStore')(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const currentPath = routerContext.location.pathname;
    const isCurrentUrl = props.navigationStore.isCurrentUrl;
    const handleClick = props.navigationStore.handleClick;

    const footerMenu = [
      {
        title: 'Impressum',
        url: '/impressum/',
        rel: 'nofollow',
      },
      {
        title: 'Datenschutzerklärung',
        url: '/datenschutzerklaerung/',
        rel: 'nofollow',
      },
      {
        title: 'Sanoa.net',
        url: 'https://sanoa.net/',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ];

    return (
      <nav className="mink-footer-nav">
        <div className="row">
          <div className="col-12">
            <div className="mink-footer-nav__menu">
              <SubMenu
                items={footerMenu}
                depthLevel={0}
                isCurrentUrl={isCurrentUrl}
                handleClick={handleClick}
                currentPath={currentPath}
              />
            </div>
          </div>
        </div>
      </nav>
    );
  })
);

export default FooterNav;
