import React, { useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle, renderMetaDesc } from '../../helpers/api';
import { renderPostFields } from '../../helpers/render';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import ScrollTopButton from '../../components/nav/ScrollTopButton/ScrollTopButton';
import { RouterContext } from '../../stores/RouterContext';
import HeaderPortal from '../../components/header/Header/HeaderPortal';
import FooterPortal from '../../components/footer/Footer/FooterPortal';

/**
 * Displays the portal page
 */
const Portal = inject(
  'portalStore',
  'windowStore'
)(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const location = routerContext.location;
    const slug = routerContext.slug;
    const postType = routerContext.postType;
    const postKey = props.portalStore.getPostKey(slug, postType);
    const isFetched = props.portalStore.isFetched.get(postKey);
    const post = props.portalStore.data.posts[postKey];
    const scrollToTop = !location.hash && (!location.state || !location.state.scrollTo);

    const renderHead = () => {
      if (props.portalStore.isFetched.get(postKey)) {
        const post = props.portalStore.data.posts[postKey];
        if (post.title) {
          return renderTitle(post.title, false);
        }
      } else {
        return null;
      }
    };

    const handleRenderMetaDesc = () => {
      const store = props.portalStore;
      if (store.isFetched.get(postKey) && store.data.posts[postKey] && store.data.posts[postKey].meta_desc) {
        const post = store.data.posts[postKey];
        const desc = post.meta_desc;

        return renderMetaDesc(desc);
      } else {
        return null;
      }
    };

    useEffect(() => {
      props.portalStore.fetchIfNeeded(postKey, slug, postType);
      document.body.classList.add('page');

      return () => document.body.classList.remove('page');
    }, [postKey, slug, postType, props.portalStore]);

    if (isFetched && false === post) {
      return <NoMatch noLocation={true} />;
    }

    return (
      <div className="sn-portal">
        {isFetched && post ? (
          <>
            {scrollToTop && <ScrollToTop onHistoryPush={true} />}
            <ScrollToHash offset={-75} />
            {renderHead()}
            {handleRenderMetaDesc()}
            {<HeaderPortal post={post} />}
            <div className="container">
              <div className="row">
                <div className="col-12 -post-content">{renderPostFields(post)}</div>
                <FooterPortal />
              </div>
            </div>
            <ScrollTopButton />
          </>
        ) : (
          <LoadingAnimation />
        )}
      </div>
    );
  })
);

export default Portal;
