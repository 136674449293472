export default class TrackingStore {
  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;

  restBase = 'sanoa';

  constructor(api) {
    this.api = api;
  }

  /**
   * @param {string}  option
   */

  saveConsentModalData(option) {
    const currentPath = window.location.href;

    this.api.tracking = this.api.registerRoute(this.restBase + '/v1', '/measurement/', { params: ['param'] });
    this.api.tracking().update({ option: option, currentPath: currentPath });
  }
}
