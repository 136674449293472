import { action, observable, makeObservable, runInAction } from 'mobx';
import { getFormatedOpeningHours } from '../../helpers/stores';

export default class locatorStore {
  bounds = {};
  center = false;
  locations = new Map();
  isFetched = new Map();
  partners = {};
  brands = {};
  allBrands = {};
  allServices = {};
  services = {};
  config = {};
  isInitialFetched = false;
  isFetching = false;
  mapIsReady = false;
  showFilters = false;
  restBase = 'sanoa';
  markers = {};
  currentLocations = [];
  filteredLocations = [];
  activeFilterIds = {
    partners: [],
    brands: [],
    services: [],
  };
  formFields = {
    partners: {},
    brands: {},
    services: {},
  };
  hoveredLocation = null;
  canSearch = false;
  // for starting in List View
  fallbackBounds = {
    south: 48.12193751078645,
    west: -0.004830271442903822,
    north: 54.889105516046236,
    east: 20.451712697307094,
  };

  constructor(api) {
    this.api = api;
    // wp-json/sanoa/v1/locator/
    this.api.locator = this.api.registerRoute(this.restBase + '/v1', '/locator/', { params: ['param'] });
    makeObservable(this, {
      bounds: observable,
      locations: observable,
      partners: observable,
      brands: observable,
      services: observable,
      isInitialFetched: observable,
      mapIsReady: observable,
      setMapIsReady: action,
      setBounds: action,
      fetch: action,
      fetchIfNeeded: action,
      setPartners: action,
      setBrands: action,
      setServices: action,
      isFetching: observable,
      isFetched: observable,
      showFilters: observable,
      setShowFilters: action,
      currentLocations: observable,
      filteredLocations: observable,
      activeFilterIds: observable,
      hoveredLocation: observable,
      canSearch: observable,
      setFilteredLocations: action,
      formFields: observable,
      onClickCheckbox: action,
      onMouseEnterLocation: action,
      onMouseLeaveLocation: action,
    });
  }

  paramsToKey(params) {
    return JSON.stringify(params);
  }

  /**
   * action
   * @param {*} bounds
   */
  setBounds(bounds) {
    this.bounds = bounds;
    if (this.isInitialFetched || this.config.initial_search_on_demand) {
      this.canSearch = true;
    }
  }

  /**
   * action
   * @param {boolean} value
   */
  setMapIsReady = (value) => {
    this.mapIsReady = value;
  };

  /**
   * action
   */
  setShowFilters = () => {
    this.showFilters =
      !this.config.show_filters.none && Array.isArray(this.currentLocations) && !!this.currentLocations.length;
  };

  /**
   * @param {Object} props
   * Input Example:
   * {
   *   "partner": {
   *     "term_id": number,
   *     "name": string,
   *     "slug": string
   *   },
   *   "brands": [
   *     {
   *       "term_id": number,
   *       "name": string,
   *       "slug": string
   *     },
   *   ],
   *   "services": [
   *     {
   *       "term_id": number,
   *       "name": string,
   *       "slug": string
   *     },
   *   ],
   *   "show_non_members": true,
   *   "settings": {
   *     "lat": float,
   *     "lng": float,
   *     "zoom": number,
   *   },
   *   "show_filters": {
   *    "mink_fc_locator_map_filters_all": boolean,
   *    "mink_fc_locator_map_filters_partner": boolean,
   *    "mink_fc_locator_map_filters_brands": boolean,
   *    "mink_fc_locator_map_filters_services": boolean
   *   },
   *   "show_maps_ui": boolean,
   *   "show_search": boolean,
   *   "start_map_view": boolean
   * }
   */
  setSettings = (props) => {
    this.config = {};
    for (const key in props) {
      if (key.startsWith('mink_fc_locator_map_')) {
        const shortKey = key.replace('mink_fc_locator_map_', '');
        if ('show_filters' === shortKey) {
          this.config[shortKey] = {};
          const showAll = !!props[key]['mink_fc_locator_map_filters_all'];
          let showNone = !showAll;
          for (const filterKey in props[key]) {
            const shortFilterKey = filterKey.replace('mink_fc_locator_map_filters_', '');
            this.config[shortKey][shortFilterKey] = showAll || props[key][filterKey];
            showNone = showNone && !this.config[shortKey][shortFilterKey];
          }
          this.config[shortKey]['none'] = showNone;
        } else {
          this.config[shortKey] = props[key];
        }
      }
    }
    // default values
    if (!this.config.settings) {
      this.config.settings = {
        zoom: 6,
        lat: 51.16354438891926,
        lng: 10.447681540164758,
      };
    }
    this.config.settings.center = {
      lat: this.config.settings.lat,
      lng: this.config.settings.lng,
    };

    // set Formfields if there are some partners, brands, services in config
    if (!this.isInitialFetched) {
      if (this.config.partner) {
        this.formFields.partners[this.config.partner.slug] = true;
      }
      if (this.config.brands && this.config.brands.length) {
        for (let brand of this.config.brands) {
          this.formFields.brands[brand.slug] = true;
        }
      }
      if (this.config.services && this.config.services.length) {
        for (let service of this.config.services) {
          this.formFields.services[service.slug] = true;
        }
      }
    }
    return this.config;
  };
  setInitialFiltersFromCongig = (params) => {
    if (this.config.partner) {
      params.partners.push(this.config.partner.slug);
    }
    if (this.config.brands && this.config.brands.length) {
      for (let brand of this.config.brands) {
        params.brands.push(brand.slug);
      }
    }
    if (this.config.services && this.config.services.length) {
      for (let service of this.config.services) {
        params.services.push(service.slug);
      }
    }
  };

  setPartners() {
    const partners = {};
    for (const location of this.currentLocations) {
      if (location.partner.ID) {
        const partner = {
          id: location.partner.ID,
          slug: location.partner.slug,
          title: location.partner.title,
          logoUrl: location.partner.logo?.url,
          logoWidth: location.partner.logo?.width,
          logoHeight: location.partner.logo?.height,
          logoAlt: location.partner.logo?.alt,
        };
        partners[partner.slug] = partner;
        if (!this.formFields.partners.hasOwnProperty(partner.slug)) {
          this.formFields.partners[partner.slug] = false;
        }
      }
    }
    this.partners = partners;
  }

  setBrands(brands) {
    this.allBrands = brands;
    if (this.config.brands && this.config.brands.length) {
      this.brands = {};
      for (const termId in brands) {
        if (-1 !== this.config.brands.findIndex((confBrand) => confBrand.slug === brands[termId].slug)) {
          this.brands[termId] = brands[termId];
        }
      }
    } else {
      this.brands = brands;
    }
    for (const index in this.brands) {
      if (!this.formFields.brands.hasOwnProperty(this.brands[index].slug)) {
        this.formFields.brands[this.brands[index].slug] = false;
      }
    }
  }

  setServices(services) {
    this.allServices = services;
    if (this.config.services && this.config.services.length) {
      this.services = {};
      for (const termId in services) {
        if (-1 !== this.config.services.findIndex((confService) => confService.slug === services[termId].slug)) {
          this.services[termId] = services[termId];
        }
      }
    } else {
      this.services = services;
    }
    for (const index in this.services) {
      if (!this.formFields.services.hasOwnProperty(this.services[index].slug)) {
        this.formFields.services[this.services[index].slug] = false;
      }
    }
  }

  setOpeningHours() {
    const locations = this.currentLocations;
    for (const i in locations) {
      if (locations[i].hours_formatted) {
        locations[i].formatedOpeningHours = getFormatedOpeningHours(locations[i]);
      }
    }
  }

  async fetchIfNeeded() {
    // if we start in list view we use default bounds
    if (!this.isInitialFetched && !this.bounds.north) {
      this.setBounds(this.fallbackBounds);
    }
    const params = {
      south: this.bounds.south,
      west: this.bounds.west,
      north: this.bounds.north,
      east: this.bounds.east,
      // partners: ['hempel-gesundheitspartner-gmbh', 'in-petto'],
      partners: this.config.partner?.slug ? [this.config.partner.slug] : this.activeFilterIds.partners,
      brands: this.activeFilterIds.brands.map((id) => this.brands[id].slug),
      services: this.activeFilterIds.services.map((id) => this.services[id].slug),
    };
    if (this.config.hasOwnProperty('show_non_members')) {
      params.show_non_members = this.config.show_non_members;
    }
    if (!this.isInitialFetched) {
      this.setInitialFiltersFromCongig(params);
    }

    const key = this.paramsToKey(params);
    this.canSearch = false;
    if (this.isFetched.get(key)) {
      const data = this.locations.get(key);
      this.currentLocations = data.locations;
      this.setShowFilters();
      this.setBrands(data.brands);
      this.setServices(data.services);
      this.setPartners();
      this.setOpeningHours();
    } else {
      this.fetch(params);
    }
  }

  /**
   * @param {object} params
   */
  async fetch(params) {
    // check for object param
    this.isFetching = true;

    this.api
      .locator()
      .create(params)
      .then((result) => {
        if (result.locations) {
          const key = this.paramsToKey(params);
          this.locations.set(key, result);
          this.isFetched.set(key, true);
          this.currentLocations = result.locations;
          this.setShowFilters();
          this.setBrands(result.brands);
          this.setServices(result.services);
          this.setPartners();
          this.setFilteredLocations();
          this.setOpeningHours();
        }
        runInAction(() => {
          this.isFetching = false;
          this.isInitialFetched = true;
        });
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  }

  setFilteredLocations = (type = 'all') => {
    // set the filter Ids
    if ('all' === type || 'partners' === type) {
      const fields = this.formFields.partners;
      this.activeFilterIds.partners = [];
      for (const key in fields) {
        if (true === fields[key]) {
          this.activeFilterIds.partners.push(key);
        }
      }
    }
    if ('all' === type || 'brands' === type) {
      this.activeFilterIds.brands = [];
      for (const term_id in this.brands) {
        const brand = this.brands[term_id];
        if (true === this.formFields.brands[brand.slug]) {
          this.activeFilterIds.brands.push(parseInt(term_id, 10));
        }
      }
    }
    if ('all' === type || 'services' === type) {
      this.activeFilterIds.services = [];
      for (const term_id in this.services) {
        const service = this.services[term_id];
        if (true === this.formFields.services[service.slug]) {
          this.activeFilterIds.services.push(parseInt(term_id, 10));
        }
      }
    }
    // filter the locations
    if (this.currentLocations.length) {
      this.filteredLocations = this.currentLocations.filter((location, index) => {
        const partners = this.activeFilterIds.partners;
        if (partners.length && -1 === partners.indexOf(location.partner.slug)) {
          return false;
        }
        const brands = this.activeFilterIds.brands;
        if (brands.length) {
          // which ids are in both arrays?
          const intersection = brands.filter((brand) => location.brands.includes(brand));
          return intersection.length > 0;
        }
        const services = this.activeFilterIds.services;
        if (services.length) {
          // which ids are in both arrays?
          const intersection = services.filter((service) => location.services.includes(service));
          return intersection.length > 0;
        }
        return true;
      });
    }
  };

  onClickCheckbox = (item, type) => {
    let slug;
    if ('string' === typeof item || item instanceof String) {
      slug = item;
    } else {
      slug = item[1].slug;
    }

    this.formFields[type][slug] = !this.formFields[type][slug];
    this.setFilteredLocations(type);
  };
  onMouseEnterLocation = (location) => {
    //this.hoveredLocation = location.slug;
    if (this.markers[location.slug]) {
      this.markers[location.slug].setAnimation(1.0);
    }
  };
  onMouseLeaveLocation = (location) => {
    //console.log('Leave', location.slug);
    //this.hoveredLocation = null;
    if (this.markers[location.slug]) {
      this.markers[location.slug].setAnimation(null);
    }
  };
}
