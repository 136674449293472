/**
 * returns a date which represents the start of the day from given date
 * Weekday Mon Day Year 00:00:00 UTC
 * @param {Date} date
 * @returns Date
 */
export const dateToDay = (date) => {
  const dd = String(date.getUTCDate()).padStart(2, '0');
  const mm = String(date.getUTCMonth() + 1).padStart(2, '0');
  const yyyy = date.getUTCFullYear();

  const todayStr = yyyy + '-' + mm + '-' + dd;
  const today = new Date(todayStr);
  return today;
};

export const googleDateToStr = (googlDateObject) => {
  // 'Y/m/d'
  const dd = String(googlDateObject.day).padStart(2, '0');
  const mm = String(googlDateObject.month).padStart(2, '0');
  const yyyy = googlDateObject.year;
  return yyyy + '-' + mm + '-' + dd;
};

export const googleTimeToStr = (googlTimeObject) => {
  // 'H:i'
  const h = String(googlTimeObject.hours).padStart(2, '0');
  const m = googlTimeObject.hasOwnProperty('minutes') ? String(googlTimeObject.minutes).padStart(2, '0') : '00';
  return h + ':' + m;
};
