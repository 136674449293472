import React from 'react';

const BlockButton = (props) => {
  const getContent = () => {
    const content = (
      <div className="-blocker-text">
        <p>
          Mit dem Anzeigen der Karte akzeptieren Sie die{' '}
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="nofollow noopener noreferrer">
            Datenschutzerklärung
          </a>{' '}
          von GoogleMaps.
        </p>
      </div>
    );
    return content;
  };

  const style = props.thumbnail
    ? {
        backgroundImage: `url(${props.thumbnail})`,
      }
    : {};
  if (props.height) {
    style.height = props.height + 'px';
  }
  return (
    <div className={'iframe-block google-maps -finder'} style={style}>
      <div className={'iframe-block__caption'}>
        <button className={'iframe-block__button'} onClick={(e) => props.onClick('allowGoogleMaps', e)}>
          <span className="-text">Empfohlene Sanitätshäuser finden</span>
        </button>
        {getContent()}
      </div>
    </div>
  );
};

export default BlockButton;
