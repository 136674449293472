import { action, observable, makeObservable } from 'mobx';

export default class PhoneStore {
  currentLocationBoxPhone = null;
  currentFooterPhone = null;

  constructor() {
    makeObservable(this, {
      currentLocationBoxPhone: observable,
      currentFooterPhone: observable,
      setCurrentCTAPhone: action,
      reset: action,
    });
  }

  reset = () => {
    this.currentLocationBoxPhone = null;
    this.currentFooterPhone = null;
  };

  setCurrentCTAPhone(phone, both = true) {
    this.currentFooterPhone = phone;
    if (both) {
      this.currentLocationBoxPhone = phone;
    }
  }
}
