import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getConfig, renderText } from '../../../helpers/api';
import { Helmet } from 'react-helmet';

/**
 * ACF Flexible Content Field
 * embeds the reviewform html located in the postcontent of choosen page
 * loads neccessary javascripts and css for cf7 wp plugin
 * @todo use the wp cf7 api to avoid loading jQuery code
 * @field mink_fc_review_form
 * @option location (only current location in dropdown, user can select positive or negative)
 * @option partner (all locations of current partner in dropdown)
 */
const ACFReview = inject('formStore')(
  observer((props) => {
    let wpcf7AssetsCSS = [];
    const formStore = props.formStore;
    const wpcf7_assets = getConfig('wpcf7_assets');

    if (wpcf7_assets && wpcf7_assets.css) {
      wpcf7AssetsCSS = wpcf7_assets.css;
    }

    useEffect(() => {
      formStore.checkURLHashParam();
      formStore.onReadyForm();

      if (formStore.allowLoadScript) {
        formStore.loadFormScripts();
      }
    }, [formStore]);

    return (
      <div className="acf-review -location-form">
        {formStore.loadStyles ? (
          <Helmet>
            {wpcf7AssetsCSS.map((style, i) => {
              return <link key={i} rel="stylesheet" href={style}></link>;
            })}
          </Helmet>
        ) : null}
        {renderText(props.content.mink_fc_review_form_post.post_content)}
      </div>
    );
  })
);

export default ACFReview;
