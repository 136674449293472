import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../../helpers/api';

const ACFAnchor = inject('onPageNavStore')(
  observer((props) => {
    const content = props.content;
    const { subscribeToOnPageNav, unsubscribeFromOnPageNav } = props.onPageNavStore;
    const [id, setId] = useState(null);
    useEffect(() => {
      if (!id) {
        const currentId = subscribeToOnPageNav(content.mink_fc_anchor_text);
        setId(currentId);
      } else {
        return () => {
          unsubscribeFromOnPageNav(id);
        };
      }
    }, [id, content.mink_fc_anchor_text, subscribeToOnPageNav, unsubscribeFromOnPageNav]);
    if (!id) {
      return null;
    }

    return (
      <h2 key={id} id={id} className="mink-fc-anchor">
        {renderText(content.mink_fc_anchor_text)}
      </h2>
    );
  })
);

export default ACFAnchor;
