import { action, observable, makeObservable } from 'mobx';

export default class FormStore {
  formsCount = 0;
  formsReady = 0;
  scriptsAlreadyLoaded = false;
  allowLoadScript = false;
  loadStyles = false;

  constructor() {
    makeObservable(this, {
      loadStyles: observable,
      formsCount: observable,
      allowLoadScript: observable,
      scriptsAlreadyLoaded: observable,
      formsReady: observable,
      loadFormScripts: action,
      reset: action,
      setFormsCount: action,
      onReadyForm: action,
    });
  }

  /**
   * set whole number of form elements to render
   *
   * @param {integer} count number of all form elements to render
   * @returns void
   */
  setFormsCount = (count) => {
    if (!this.formsCount) {
      this.formsCount = count;
    }
  };

  /**
   * increment a number of component is already loaded and check if is that last element, when yes, allow load the scripts
   *
   * @returns void
   */
  onReadyForm = () => {
    this.formsReady++;

    if (this.formsReady === this.formsCount) {
      this.allowLoadScript = true;
    }
  };

  /**
   * check if URL has a '#review-form' hash parameter, if given scroll to the review form and open it
   *
   * @returns void
   */
  checkURLHashParam = () => {
    if ('#review-form' === window.location.hash) {
      const reviewCheckbox = document.querySelector('[name="mink-opinion[]"]');
      reviewCheckbox && reviewCheckbox.click();
    }
  };

  /**
   * reset currently form store state
   *
   * @returns void
   */
  reset = () => {
    this.allowLoadScript = false;
    this.scriptsAlreadyLoaded = true;
    this.formsCount = 0;
    this.formsReady = 0;
  };

  /**
   * if scripts are already loaded, initialise exists forms
   *
   * @returns void
   */
  initExistsWpcf7Forms = () => {
    window.jQuery('.wpcf7-form').each(function (index) {
      try {
        window.wpcf7.reset(window.jQuery(this)[0]);
      } catch {
        window.wpcf7.init(window.jQuery(this)[0]);
      }
    });
    window.wpcf7cf.initForm(window.jQuery('.wpcf7-form'));
    window.initDragDrop();
    this.reset();
  };

  /**
   * load all form scripts
   *
   * @returns void
   */
  loadFormScripts = () => {
    if (this.scriptsAlreadyLoaded) {
      this.initExistsWpcf7Forms();
    } else {
      let wpcf7AssetsJS = [];
      let wpcf7AssetsExtras = [];

      if (window.SN && window.SN.wpcf7_assets && window.SN.wpcf7_assets) {
        wpcf7AssetsJS = window.SN.wpcf7_assets.js;
        wpcf7AssetsExtras = window.SN.wpcf7_assets.extras;
      }

      if (wpcf7AssetsJS.length) {
        wpcf7AssetsExtras.forEach((extra) => {
          const dataScript = document.createElement('script');
          dataScript.innerHTML = extra;
          document.body.appendChild(dataScript);
        });

        const loadScript = (scriptIndex) => {
          const scriptObj = wpcf7AssetsJS[scriptIndex];
          const srcScript = document.createElement('script');
          srcScript.onload = () => {
            if (scriptIndex + 1 !== wpcf7AssetsJS.length) {
              loadScript(scriptIndex + 1);
            } else {
              this.reset();
              window.document.dispatchEvent(new Event('DOMContentLoaded'));
            }
          };
          srcScript.src = scriptObj;
          document.body.appendChild(srcScript);
        };
        loadScript(0);
        this.loadStyles = true;
      }
    }
  };
}
