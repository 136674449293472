import { action, observable, makeObservable, runInAction } from 'mobx';
import { setPartnerData } from '../helpers/stores';

export default class PartnerStore {
  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  data = {
    posts: {},
    ids: [],
    meta: {},
  };
  isFetched = new Map();
  isFetching = [];
  namespace = 'sanoa';
  currentSlug = false;
  extScriptLoaded = false;
  extScriptLoading = false;

  constructor(api, routerStore) {
    this.api = api;
    this.routerStore = routerStore;
    makeObservable(this, {
      isFetched: observable,
      currentSlug: observable,
      extScriptLoaded: observable,
      extScriptLoading: observable,
      fetch: action,
      setCurrentSlug: action,
      loadExtScript: action,
    });
  }

  setCurrentSlug = (slug) => {
    this.currentSlug = slug;
  };

  /**
   * @todo get rewrite slug from matching route for retrieving custom posttypes, f.e. path: "/videos/:id/"
   * @param {object} params
   */
  fetch(params) {
    // check for object param
    let { path, slug } = params;
    let restBase = '/partner/(?P<id>[\\w-]+)';
    path = !path ? slug : path;
    this.isFetching[path] = true;
    this.api.page = this.api.registerRoute(this.namespace + '/v1', restBase, { params: ['param'] });
    this.api
      .page()
      .id(slug)
      .then((post) => {
        if (post && (post.hasOwnProperty('ID') || post.hasOwnProperty('partner'))) {
          setPartnerData(post);
          this.data.posts[path] = post;
        } else {
          this.data.posts[path] = false;
        }
        this.isFetched.set(path, true);
        this.isFetching[path] = false;
      })
      .catch((error) => {
        console.error(error);
        this.data.posts[path] = false;
        this.isFetched.set(path, true);
        this.isFetching[path] = false;
      });
  }

  fetchIfNeeded(slug) {
    slug = '' !== slug ? slug : 'partner';
    if (!this.isFetching[slug] && !this.isFetched.get(slug)) {
      this.fetch({ slug: slug });
    }
  }

  /**
   * load js file for third party app for partner
   * we need the slug as param because we use these not only on partner pages
   * so partner data can be fetched in other stores
   *
   * @param {String} slug
   */
  loadExtScript(slug) {
    if (!this.extScriptLoaded && !this.extScriptLoading) {
      try {
        const srcScript = document.createElement('script');
        srcScript.onload = () => {
          runInAction(() => {
            this.extScriptLoading = false;
            this.extScriptLoaded = true;
          });
        };
        this.extScriptLoading = true;
        srcScript.src = `${process.env.PUBLIC_URL}/partner/${slug}.js`;
        document.body.appendChild(srcScript);
      } catch (e) {
        console.info(e);
      }
    }
  }
}
