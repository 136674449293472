import React from 'react';
import { renderText } from '../../../helpers/api';

const DaysHours = (props) => {
  const location = props.location;
  const today = new Date();
  const todayWeekday = today.toLocaleDateString('de-DE', { weekday: 'long' });

  const formatedOpeningHours = location.formatedOpeningHours;

  if (formatedOpeningHours) {
    return Object.keys(formatedOpeningHours).map((dayName, index) => {
      const hours = formatedOpeningHours[dayName];
      const mb = hours.isSpecial === true ? ' mb-1' : '';
      return (
        <div className={'row' + (todayWeekday === dayName ? ' font-weight-bold current-day' : '')} key={dayName}>
          <div className="col-4 col-sm-4">{renderText(dayName)}</div>
          <div className={'col-8' + mb}>{renderText(hours.lines.join(`<br>`))}</div>
        </div>
      );
    });
  }
  return null;
};

export default DaysHours;
