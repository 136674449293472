import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import useEventListener from '@use-it/event-listener';

const ScrollTopButton = inject('scrollStore')(
  observer((props) => {
    const [visible, setVisible] = useState(false);

    const onWindowScroll = () => {
      if (window?.scrollY > 400) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    useEventListener('scroll', onWindowScroll, window, { passive: true });

    const topClick = () => {
      if (typeof window !== 'undefined') {
        props.scrollStore.animateScrollTo({ desiredOffset: 0 });
      }
    };

    return (
      <div
        title="Zum Seitenanfang"
        className={'mink-scroll-top-button' + (visible ? ' is-visible' : '')}
        onClick={topClick}
      ></div>
    );
  })
);

export default ScrollTopButton;
