import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { isMobile } from '../../../helpers/browser';
import { trackingClassName } from '../../../helpers/api';

const ACFCtaButton = inject('phoneStore')(
  observer((props) => {
    const content = props.content;
    const slug = props.slug;
    const location = props.location;
    const partner = props.partner;
    const trackingParams = {
      partnerSlug: partner.slug,
      postType: props.post_type,
      slug: slug,
    };

    useEffect(() => {
      if (location) {
        const phoneStore = props.phoneStore;
        const type = content.mink_fc_cta_type;
        if ('partner' === type) {
          const phone = partner.phone;
          phoneStore.setCurrentCTAPhone(phone);
        } else if ('tel' === type) {
          phoneStore.setCurrentCTAPhone(content.mink_fc_cta_tel, false);
        }
      }
    }, [content.mink_fc_cta_type, content.mink_fc_cta_tel, props.phoneStore, location, partner]);

    const getTel = (props) => {
      // partner or location
      if (slug) {
        const type = props.content.mink_fc_cta_type;
        if ('partner' === type) {
          const phone = props.partner.phone;
          return phone;
        } else if ('tel' === type) {
          return props.content.mink_fc_cta_tel;
        } else {
          return location.phone;
        }
      }
    };

    const getLink = (props) => {
      const type = props.content.mink_fc_cta_type;
      let text = props.content.mink_fc_cta_text;
      let href = '';
      if ('url' === type) {
        href = props.content.mink_fc_cta_href;
      } else if ('recruiting' === type && partner.social_networks?.recruiting_link?.url) {
        href = partner.social_networks.recruiting_link.url;
      } else {
        const phone = getTel(props);
        if (isMobile()) {
          href = phone ? 'tel:' + phone : '';
        } else {
          // no phone link on desktop
          text += phone ? ' ' + phone : '';
        }
      }

      if (href) {
        return (
          <a className={'btn-cta ' + trackingClassName(trackingParams, 'cta')} href={href}>
            {text}
          </a>
        );
      }

      return <span className={'btn-cta noclick'}>{text}</span>;
    };

    return <div className="mink-cta-button">{getLink(props)}</div>;
  })
);

export default ACFCtaButton;
