import React from 'react';
import { renderText } from '../../../helpers/api';

/**
 * Agenda element to display steps with custom icons
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#Agenda
 */

const ACFAgenda = (props) => {
  const agendaItems = props.content.mink_fc_agenda_items;
  const AgendaItem = ({ item, elIndex }) => {
    return (
      <div className="mink-agenda-item" key={elIndex}>
        <div className="mink-agenda-item__header">
          <div className="-icon">
            {item.mink_fc_agend_item_img && (
              <img src={item.mink_fc_agend_item_img.url} alt={item.mink_fc_agend_item_img.alt} />
            )}
          </div>
          <h4 className="-title">{renderText(item.mink_fc_agenda_item_title)}</h4>
        </div>
        <div className="mink-agenda-item__content">{renderText(item.mink_fc_agenda_item_text)}</div>
      </div>
    );
  };

  return (
    <div className="mink-agenda">
      {agendaItems.map((item, i) => {
        return <AgendaItem key={i} item={item} elIndex={i} />;
      })}
    </div>
  );
};

export default ACFAgenda;
