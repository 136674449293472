import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { UncontrolledCollapse } from 'reactstrap';
import { getCollapseThreshold, renderText } from '../../../helpers/api';
import CollapseToggler from '../../nav/CollapseToggler/CollapseToggler';
import UniLink from '../../elements/UniLink/UniLink';

/**
 * List with text elements and the posibility to generate structured faq json
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#List
 */

const ACFList = inject('windowStore')(
  observer((props) => {
    const content = props.content;
    const mainEntity = [];
    let listFaqObj = {};
    let showScriptTag = false;
    const uniqueIdRef = useRef(Math.floor(Math.random() * 1000000000));

    if (content.mink_fc_list_faq && content.mink_fc_list_items && content.mink_fc_list_items.length) {
      content.mink_fc_list_items.forEach((el) => {
        mainEntity.push({
          '@type': 'Question',
          name: el.title,
          acceptedAnswer: { '@type': 'Answer', text: el.content },
        });
      });

      listFaqObj = { '@context': 'https://schema.org', '@type': 'FAQPage', mainEntity: mainEntity };
      showScriptTag = true;
    }
    const toggleCollapse = (e) => {
      e.currentTarget.classList.toggle('open');
      e.currentTarget.classList.toggle('closed');
    };

    const store = props.windowStore;
    const isCollapse = store.isSmallerThan('lg');
    const uniqueId = uniqueIdRef.current;
    const items = content.mink_fc_list_items.length ? [...content.mink_fc_list_items] : [];
    const t = getCollapseThreshold();
    const itemsCollapsed = t + 1 < items.length ? items.splice(t) : [];

    const ListElement = ({ listElement, elIndex }) => {
      if ((listElement.title && listElement.content) || listElement.link) {
        const id = 'toggle-content-' + uniqueId + '-' + elIndex;
        if (listElement.link && !listElement.title && !listElement.content) {
          return (
            <div className="mink-list__item" key={elIndex}>
              <UniLink className="-link" href={listElement.link.url} target={listElement.link.target}>
                {renderText(listElement.link.title)}
              </UniLink>
            </div>
          );
        }
        return isCollapse ? (
          <div className="mink-list__item" key={elIndex}>
            <h4 className={'closed'} id={id} onClick={toggleCollapse}>
              {renderText(listElement.title)}
            </h4>
            <UncontrolledCollapse className="" toggler={id} toggleEvents={['click']}>
              <div className="mink-list__content">{renderText(listElement.content)}</div>
            </UncontrolledCollapse>
          </div>
        ) : (
          <div className="mink-list__item" key={elIndex}>
            <h4>{renderText(listElement.title)}</h4>
            <div className="mink-list__content">{renderText(listElement.content)}</div>
          </div>
        );
      }
      return null;
    };

    return (
      <>
        <div className="mink-list">
          {items.map((item, i) => {
            return <ListElement key={i} listElement={item} elIndex={i} />;
          })}
          {0 < itemsCollapsed.length && (
            <>
              <UncontrolledCollapse toggler={'toggle-show-more-' + uniqueId} toggleEvents={['click']}>
                {itemsCollapsed.map((item, i) => {
                  return <ListElement key={i} listElement={item} elIndex={t + i} />;
                })}
              </UncontrolledCollapse>
              <CollapseToggler id={'toggle-show-more-' + uniqueId} moreCount={itemsCollapsed.length} />
            </>
          )}
        </div>
        {showScriptTag ? (
          <script type="application/ld+json" data-gen="dyn">
            {JSON.stringify(listFaqObj)}
          </script>
        ) : null}
      </>
    );
  })
);

export default ACFList;
