import { useState, useEffect, useContext } from 'react';
import { WINDOW_EXISTS } from '../../../helpers/viewport';
import { RouterContext } from '../../../stores/RouterContext';

const ScrollToTop = (props) => {
  const routerContext = useContext(RouterContext);
  const [shouldScroll] = useState(!props.onHistoryPush || 'PUSH' === routerContext.navigationType);

  useEffect(() => {
    if (shouldScroll && WINDOW_EXISTS) {
      window.scrollTo(0, 0);
    }
  }, [shouldScroll]);
  return null;
};
export default ScrollToTop;
