import React from 'react';
import { renderText } from '../../../helpers/api';

const LocationsList = ({ locations, onMouseEnterLocation, onMouseLeaveLocation }) => {
  return (
    <>
      {Array.isArray(locations) && (
        <ul>
          {locations.map((location, i) => {
            return (
              <li
                key={location.slug}
                onMouseEnter={(e) => {
                  if (onMouseEnterLocation) {
                    onMouseEnterLocation(location);
                  }
                }}
                onMouseLeave={(e) => {
                  if (onMouseLeaveLocation) {
                    onMouseLeaveLocation(location);
                  }
                }}
              >
                <div className="acf-locator__list-item">
                  <div className="-logo">
                    {location.partner.logo && (
                      <img
                        width={location.partner.logo.width}
                        height={location.partner.logo.height}
                        src={location.partner.logo.url}
                        alt={location.partner.logo.alt}
                        title={location.partner.title}
                      />
                    )}
                  </div>
                  <div className="-text">
                    <h3>{renderText(location.title)}</h3>
                    <p>
                      {location.address.street} {location.address.street_number}
                      <br />
                      {location.address.zip} {location.address.city}
                    </p>
                    {location.url && (
                      <p>
                        <a href={location.url} className="mink-btn-colored" target="_blank" rel="noopener noreferrer">
                          Zum Standort
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default LocationsList;
