import { action, observable, makeObservable } from 'mobx';

export default class RouterStore {
  currentSlug = false;
  navigate = null;
  location = window?.location;
  match = null;
  navigationType = '';

  constructor() {
    this.navigationType = '';
    makeObservable(this, {
      currentSlug: observable,
      location: observable,
      match: observable,
      navigationType: observable,
      syncRouterWithStore: action,
      setCurrentSlug: action,
    });
  }

  syncRouterWithStore = (navigate, location, navigationType, match) => {
    this.location = location;
    this.navigate = navigate;
    this.navigationType = navigationType;
    this.match = match;
    this.setCurrentSlug();
  };

  navigate = (path) => {
    this.navigate(path);
  };

  getCurrentSlug = () => {
    return this.currentSlug;
  };

  /**
   * sets the current slug, for use outside components with RouterContext
   * @action
   */
  setCurrentSlug = () => {
    const location = this.location;
    const match = this.match;
    if (match && match.params) {
      if (match.pattern?.apiParams?.postType) {
        this.currentSlug = location.pathname;
      } else {
        const newSlug = match.params.id ? match.params.id : 'location';
        this.currentSlug = newSlug;
      }
    }
  };
}
