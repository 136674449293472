import React from 'react';
import { Route, Routes, matchPath } from 'react-router-dom';
import { default as stores } from '../stores/index';
import Location from '../containers/Location/Location';
import Partner from '../containers/Partner/Partner';
import Service from '../containers/Service/Service';
import Brand from '../containers/Brand/Brand';
import Post from '../containers/Post/Post';
import Embed from '../containers/Embed/Embed';
import Portal from '../containers/Portal/Portal';
import NoMatch from '../containers/NoMatch/NoMatch';
import { getConfig } from '../helpers/api';
import { setDevSubdomain } from '../helpers/stores';

const location = stores.routerStore.location;
const subdomain = setDevSubdomain(location);

const preparePostTypesRoutes = (customRoutes) => {
  const routes = getConfig('routes');
  const postTypeRoutes = [];
  for (const p_key in routes) {
    if ('_meta' !== p_key && routes.hasOwnProperty(p_key)) {
      const postType = routes[p_key];
      // first define route for posts of this post_type if it has rewrite slug
      // and not defined in customRoutes like members
      if (postType._rewrite) {
        let shema = '/' + postType._rewrite + '/:id';
        if (!customRoutes.find((route) => route.path === shema)) {
          postTypeRoutes.push({
            path: shema,
            exact: true,
            component: Portal,
            apiParams: {
              postType: p_key,
            },
          });
        }
        shema = '/' + postType._rewrite;
        if (shema && !customRoutes.find((route) => route.path === shema)) {
          postTypeRoutes.push({
            path: shema,
            exact: true,
            component: Portal,
            apiParams: {
              postType: p_key,
              type: 'post_type_archive',
              id: postType._id,
            },
          });
        }
      }
    }
  }
  return postTypeRoutes;
};

let hompageComponent = Portal;
let partnerPage = false;
if (!subdomain) {
  hompageComponent = Portal;
} else if (subdomain) {
  hompageComponent = Partner;
  partnerPage = true;
}

const customRoutes = [
  {
    // partner homepage or portal homepage
    path: '/',
    exact: true,
    component: hompageComponent,
    apiParams: {
      postType: hompageComponent !== Portal ? 'sanoa_partner_pages' : 'sanoa_portal',
      partnerPage: partnerPage,
    },
  },
];
if (subdomain) {
  customRoutes.push(
    ...[
      {
        // location
        path: '/standort/:id/',
        exact: true,
        component: Location,
        apiParams: {
          postType: 'sanoa_location',
          partnerPage: true,
        },
      },
      {
        // service
        path: '/standort/:location/service/:id/',
        exact: true,
        component: Service,
        apiParams: {
          postType: 'sanoa_service',
          partnerPage: true,
        },
      },
      {
        // brand
        path: '/standort/:location/marke/:id/',
        exact: true,
        component: Brand,
        apiParams: {
          postType: 'sanoa_brand',
          partnerPage: true,
        },
      },
      {
        // pages
        path: '/standort/:location/:id/',
        exact: true,
        component: Post,
      },
    ]
  );
}

customRoutes.push(
  ...[
    {
      // embeds
      path: '/embed/:id/',
      exact: true,
      component: Embed,
    },
    {
      // posts, e.g. imprint
      // path: '/:path([\\w-/]*)?/:id',
      // /:id([\\w-]*)
      path: '/:id/*',
      exact: true,
      component: Post,
    },
    {
      // anything else
      path: '*',
      component: NoMatch,
    },
  ]
);
const postTypeRoutes = preparePostTypesRoutes(customRoutes);
const ROUTES = [...postTypeRoutes, ...customRoutes];

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
const RouteWithSubRoutes = (route) => {
  const store = stores.routerStore;
  const location = store.location;
  if (route.children) {
    return (
      <Routes key={location.pathname}>
        <Route
          key={location.pathname}
          path={route.path}
          exact={route.exact}
          location={location}
          children={({ ...props }) => {
            // pass the sub-routes down to keep nesting
            return <route.children {...props} key={location.pathname} routes={route.routes} path={route.path} />;
          }}
        />
      </Routes>
    );
  } else {
    return (
      <route.component
        match={route.match}
        location={location}
        key={location.pathname}
        routes={route.routes}
        path={route.path}
        store={route.store}
        apiParams={route.apiParams}
      />
    );
  }
};

const getCurrentRoute = () => {
  const store = stores.routerStore;
  const routes = ROUTES;
  let match = null;
  for (let i = 0; i < routes.length; i++) {
    match = matchPath(routes[i], store.location.pathname);
    if (match) {
      return routes[i];
    }
  }
  return routes[routes.length - 1];
};

/**
 *
 * @param {string} location current location
 */
const getCurrentSlug = (location) => {
  const route = getCurrentRoute();
  const match = matchPath(route, location.pathname);
  if (match && match.params) {
    if (route.apiParams && route.apiParams.postType) {
      return location.pathname;
    } else {
      const newSlug = match.params.id ? match.params.id : 'location';
      return newSlug;
    }
  }
};

/**
 *
 * @param {integer} id term id
 * @param {string} taxonomy slug
 */
const getRouteByTermId = (id, taxonomy = null) => {
  const routes = ROUTES;
  return id !== undefined && routes.find((route) => route.apiParams && route.apiParams.termId === id);
};

/**
 *
 * @param {string} path
 *
 * @returns {object|boolean} route object or false
 */
const getRouteByPath = (path) => {
  const routes = ROUTES;
  return path !== undefined && routes.find((route) => matchPath(route, path));
};

export { ROUTES, RouteWithSubRoutes, getCurrentRoute, getRouteByTermId, getRouteByPath, getCurrentSlug };
