import React, { Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import VisibilitySensor from '@zelty/react-visibility-sensor';
import { trackingClassName, renderText } from '../../../helpers/api';
import Img from '../../elements/Img/Img';
import LocationBox from '../../location/LocationBox/LocationBox';
import HeaderMenuButton from '../HeaderNav/HeaderMenuButton';
import HeaderNav from '../HeaderNav/HeaderNav';
import LocationMap from '../Map/LocationMap';

/**
 * Header for locations
 */
const HeaderLocation = inject(
  'windowStore',
  'navigationStore'
)(
  observer((props) => {
    const post = props.post;
    const location = props.post.location;
    const trackingParams = {
      partnerSlug: post.partner.slug,
      postType: post.post_type,
      slug: post.slug,
    };

    const getAdress = () => {
      let address = '';
      if (location) {
        if (location.address.length) {
          address += location.address[0] + ', ';
        }
        if (location.city) {
          address += location.city;
        }
      }
      return address;
    };

    const store = props.windowStore;
    const sizeSuffix = store.getHeaderImageSizeSuffix();

    const CatchmentArea = () => {
      const catchmentArea = location?.catchment_area;
      if (catchmentArea) {
        return <div className="ida-header__catchment-area">{renderText(catchmentArea)}</div>;
      } else {
        return null;
      }
    };

    const image = post.post_thumbnail?.[sizeSuffix];
    const ImgElement = () => {
      if (post.location?.member) {
        if (image && image[0]) {
          const alt = post.post_thumbnail?.meta?.alt;
          const title = post.post_thumbnail?.meta?.title;
          return (
            <Img
              key={image[0]}
              className="ida-header__image aspect-content"
              src={image[0]}
              width={image[1]}
              height={image[2]}
              alt={alt}
              title={title}
            />
          );
        } else {
          return null;
        }
      } else {
        return (
          <img
            className="ida-header__image aspect-content"
            src={'/content/themes/sanoa/assets/images/sanoa-fallback.webp'}
            width={1067}
            height={1067}
            alt={'Sanoa Partner werden'}
          />
        );
      }
    };

    const partnerFields = post.partner;

    const customHeader = partnerFields.header && partnerFields.external_id ? partnerFields.header : '';

    const PartnerHeader =
      customHeader &&
      React.lazy(() => import(`../PartnerHeader/${partnerFields.external_id}-${partnerFields.slug}-header`));

    return customHeader ? (
      <Suspense fallback={''}>
        <PartnerHeader post={post} partner={post.partner} />
      </Suspense>
    ) : (
      <header className="ida-header">
        <div className={'ida-header__shape' + (props.navigationStore.navIsActive ? ' menu-is-open' : '')}>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex">
                <HeaderMenuButton />
                <h1>
                  {location.title && location.title}&nbsp;
                  <span className="ida-header__address">{renderText(getAdress())}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <HeaderNav post={post} navigation={post.navigation} />
        <div className="container ida-header__content">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-8">
              {post.location?.member && (
                <div className="img-container">
                  {ImgElement()}
                  <div className="ida-header__mobile-box d-block d-lg-none">
                    {location.phone && (
                      <a
                        className={'-phone ' + trackingClassName(trackingParams, 'header-telefon')}
                        href={'tel:' + location.phone}
                      >
                        {location.phone}
                      </a>
                    )}
                  </div>
                </div>
              )}
              {props.windowStore.isSmallerThan('lg') && location && (
                // mobile location box, see Location.js for desktop
                <LocationBox location={location} headerTitle={props.headerTitle} post={post} />
              )}
              <VisibilitySensor partialVisibility={true} delayedCall={true} offset={{ top: 0, bottom: 0 }}>
                {({ isVisible }) => (
                  <div className="ida-header__map">
                    <LocationMap location={location} isVisible={isVisible} post={post} />
                  </div>
                )}
              </VisibilitySensor>
              <CatchmentArea />
            </div>
            {props.windowStore.isGreaterEqualThan('lg') && props.windowStore.isSmallerThan('xl') && location && (
              <div className="col-12 col-lg-6 col-xl-4">
                <LocationBox location={location} headerTitle={props.headerTitle} post={post} />
              </div>
            )}
          </div>
        </div>
      </header>
    );
  })
);
export default HeaderLocation;
