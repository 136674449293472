import React from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../../helpers/api';

/**
 * Displays the intro headline and text from the post base data
 * Displays on service and brandpages additional location.info text with location.title
 * Displays a logo by this rules:
 * - Location: no logo
 * - Service: location logo, if empty, partner logo
 * - Brand: brand logo
 */

const ACFTopContent = inject('windowStore')(
  observer((props) => {
    const content = props.content;

    let locationInfo = false;
    let showLocationInfo = false;
    if (props.location?.info) {
      locationInfo = props.location.info;
    }

    let logo = props.logo;

    if (props.windowStore.isSmallerThan('lg')) {
      // small screens have logo on header image
      logo = false;
    } else if ('sanoa_brand' === props?.post_type) {
      showLocationInfo = true;
    } else if ('sanoa_service' === props?.post_type) {
      showLocationInfo = true;
      logo = false;
    } else {
      // sanoa_location
      logo = false;
    }

    return (
      <div className="mink-top-content">
        <div className={logo ? 'mink-top-content__content -logo' : 'mink-top-content__content'}>
          <h2>{renderText(content.mink_fc_intro_title)}</h2>
          {renderText(content.mink_fc_intro_text)}
          {showLocationInfo && locationInfo && (
            <>
              <h3>{renderText(props?.location?.title)}</h3>
              <p>{locationInfo}</p>
            </>
          )}
        </div>
        {logo && (
          <div className="mink-top-content__logo">
            <img
              src={logo.url}
              width={logo.width}
              height={logo.height}
              className=""
              alt={logo.alt}
              title={logo.title}
            />
          </div>
        )}
      </div>
    );
  })
);

export default ACFTopContent;
