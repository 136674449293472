import React from 'react';
import SubMenuItem from '../SubmenuItem/SubMenuItem';

const SubMenu = (props) => {
  const items = props.items;
  const level = props.depthLevel;

  return (
    <ul className={!level ? 'main-menu list-unstyled' : 'sub-menu list-unstyled'}>
      {items.map((item) => {
        if (item) {
          return (
            <SubMenuItem
              key={'mi_' + (item.ID ? item.ID : item.title + item.url)}
              item={item}
              depthLevel={level}
              handleClick={props.handleClick}
              isCurrentUrl={props.isCurrentUrl}
              currentPath={props.currentPath}
              setParentDefaultOpen={props.setParentDefaultOpen}
              rootElement={props.rootElement}
            />
          );
        } else {
          return null;
        }
      })}
    </ul>
  );
};

export default SubMenu;
