import { action, observable, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';
import { ROUTES, getRouteByPath } from '../routes/routes';

export default class PortalStore {
  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  data = {
    posts: {},
    ids: [],
    meta: {},
  };
  isFetched = new Map();
  isFetching = [];
  namespace = 'sanoa';
  currentSlug = false;

  constructor(api, routerStore) {
    this.api = api;
    this.routerStore = routerStore;
    makeObservable(this, {
      isFetched: observable,
      currentSlug: observable,
      fetch: action,
      setCurrentSlug: action,
    });
  }

  getCurrentRoute = () => {
    const store = this.routerStore;
    const routes = ROUTES;
    let match = null;
    for (let i = 0; i < routes.length; i++) {
      match = matchPath(routes[i], store.location.pathname);
      if (match) {
        return routes[i];
      }
    }
    return routes[routes.length - 1];
  };

  setCurrentSlug = (slug) => {
    this.currentSlug = slug;
  };

  /**
   * makes the key for fetched posts Map
   * @param {string} slug
   * @param {string} postType
   * @returns string
   */
  getPostKey = (slug, postType) => {
    return `${postType}_${slug}`;
  };

  /**
   * @todo get rewrite slug from matching route for retrieving custom posttypes, f.e. path: "/videos/:id/"
   * @param {string} postKey
   * @param {string} slug
   * @param {string} postType
   */
  fetch(postKey, slug, postType) {
    let restBase = '/portal/(?P<id>[\\w-]+)';
    this.isFetching[postKey] = true;
    this.api.page = this.api.registerRoute(this.namespace + '/v1', restBase, { params: ['param'] });
    const fetchParams = {};
    if (postType) {
      fetchParams.post_type = postType;
    }
    this.api
      .page()
      .param(fetchParams)
      .id(slug)
      .then((post) => {
        if (post && post.hasOwnProperty('ID')) {
          this.data.posts[postKey] = post;
        } else {
          this.data.posts[postKey] = false;
        }
        this.isFetched.set(postKey, true);
        this.isFetching[postKey] = false;
      })
      .catch((error) => {
        console.error(error);
        this.data.posts[postKey] = false;
        this.isFetched.set(postKey, true);
        this.isFetching[postKey] = false;
      });
  }

  fetchIfNeeded(postKey, slug, postType) {
    if (!this.isFetching[postKey] && !this.isFetched.get(postKey)) {
      this.fetch(postKey, slug, postType);
    }
  }

  fetchPathIfNeeded(path) {
    if (!this.isFetching[path] && !this.isFetched.get(path)) {
      const route = getRouteByPath(path);
      const match = route && matchPath(route, path);
      if (match) {
        const params = {
          path: path,
          slug: match.params.id,
          postType: route.apiParams.postType,
        };
        this.fetch(params);
      }
    }
  }
}
