import React from 'react';

/**
 * consent for squarespace appointments
 * @value onClick (action which saves the consent)
 * @value allowValue (parm for appointments)
 * @value privacyLink
 * @value height
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/262897672/Terminbuchung+online
 */

const ACFAppointmentIframeBlockButton = ({ height, onClick, privacyLink, allowValue, trackingClass }) => {
  const getContent = () => {
    const content = (
      <div className="-blocker-text">
        Mit dem Anzeigen des Termintools akzeptieren Sie die{' '}
        <a href={privacyLink} target="_blank" rel="nofollow noopener noreferrer">
          Datenschutzerklärung
        </a>{' '}
        von Squarespace.
      </div>
    );
    return content;
  };

  const style = {};
  if (height) {
    style.height = height + 'px';
  }
  return (
    <div className={'iframe-block -appointments'} style={style}>
      <div className={'iframe-block__caption'}>
        <button className={'iframe-block__button ' + trackingClass} onClick={(e) => onClick(allowValue, e)}>
          <span className="-text">Online-Termine anzeigen</span>
        </button>
        {getContent()}
      </div>
    </div>
  );
};

export default ACFAppointmentIframeBlockButton;
