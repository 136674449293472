import { action, observable, makeObservable } from 'mobx';
import { stripTags, sanitizeId } from '../../helpers/render';

export default class OnPageNavStore {
  items = new Map();

  constructor() {
    makeObservable(this, {
      items: observable,
      subscribeToOnPageNav: action,
      unsubscribeFromOnPageNav: action,
      getUniqueAnchorId: action,
    });
  }
  /**
   * adds item to on page nav items Map
   * @param {string} str content of the headline
   * @returns string
   */
  subscribeToOnPageNav = (str) => {
    const title = stripTags(str);
    const id = this.getUniqueAnchorId(sanitizeId(title));
    const item = {
      mink_fc_onpagenav_item: {
        title: title,
        url: '#' + id,
      },
    };
    this.items.set(id, item);
    return id;
  };

  /**
   * removes item  by given from on page nav map by given id
   * @param {string} id
   */
  unsubscribeFromOnPageNav = (id) => {
    this.items.delete(id);
  };

  /**
   * calculate a unique id string for a given id, to avoid duplicate anchors in items Map
   * @param {string} id
   * @returns {string}
   */
  getUniqueAnchorId = (id) => {
    if (this.items.has(id)) {
      let counter = 0;
      do {
        counter++;
      } while (this.items.has(`${id}-${counter}`));
      id = `${id}-${counter}`;
    }
    return id;
  };
}
