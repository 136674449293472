import React from 'react';
import { inject, observer } from 'mobx-react';
import ACFEmbedVideo from '../ACFEmbedVideo/ACFEmbedVideo';

/**
 * returns vimeo video with parms
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#Embed:-Video
 */

const ACFEmbedVimeo = inject('blockDataStore')(
  observer((props) => {
    const data = {
      provider: 'vimeo',
      className: ' -vimeo',
      embedUrl: props.content.mink_fc_embed_video_script_raw_url,
      allowVideo: props.blockDataStore.allowVimeo,
      contentUrl: props.content.mink_fc_embed_video_vimeo_url,
      title: props.content.mink_fc_embed_video_title,
      text: props.content.mink_fc_embed_video_text,
      link: props.content.mink_fc_embed_video_link,
      date: props.content.mink_fc_embed_video_upload_date,
      description: props.content.mink_fc_embed_video_script_description,
      thumbnail: props.content.mink_fc_embed_video_thumbnail,
      iframe: props.content.mink_fc_embed_video_embed,
    };

    return <ACFEmbedVideo {...data} />;
  })
);

export default ACFEmbedVimeo;
