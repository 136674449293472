import React from 'react';
import { inject, observer } from 'mobx-react';
import VisibilitySensor from '@zelty/react-visibility-sensor';
import { renderText } from '../../../helpers/api';
import Img from '../../elements/Img/Img';
import PartnerBox from '../../partner/PartnerBox/PartnerBox';
import HeaderMenuButton from '../HeaderNav/HeaderMenuButton';
import HeaderNav from '../HeaderNav/HeaderNav';
import PartnerMap from '../Map/PartnerMap';

/**
 * Header for locations
 */
const HeaderLocation = inject(
  'windowStore',
  'partnerStore',
  'navigationStore'
)(
  observer((props) => {
    const post = props.post;
    const partner = props.post.partner;

    const getAdress = () => {
      let address = '';
      if (partner) {
        if (partner.address.length) {
          address += partner.address[0] + ', ';
        }
        if (partner.city) {
          address += partner.city;
        }
      }
      return address;
    };

    const store = props.windowStore;
    const sizeSuffix = store.getHeaderImageSizeSuffix();

    const image = post.post_thumbnail?.[sizeSuffix];
    const ImgElement = () => {
      if (post.partner?.member) {
        if (image && image[0]) {
          const alt = post.post_thumbnail?.meta?.alt;
          const title = post.post_thumbnail?.meta?.title;
          return (
            <Img
              key={image[0]}
              className="ida-header__image aspect-content"
              src={image[0]}
              width={image[1]}
              height={image[2]}
              alt={alt}
              title={title}
            />
          );
        } else {
          return null;
        }
      } else {
        return (
          <img
            className="ida-header__image aspect-content"
            src={'/content/themes/sanoa/assets/images/sanoa-fallback.webp'}
            width={1067}
            height={1067}
            alt={'Sanoa Partner werden'}
          />
        );
      }
    };

    return (
      <header className="ida-header">
        <div className={'ida-header__shape' + (props.navigationStore.navIsActive ? ' menu-is-open' : '')}>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex">
                <HeaderMenuButton />
                <h1>
                  {partner.name && partner.name}&nbsp;
                  <span className="ida-header__address">{renderText(getAdress())}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <HeaderNav post={post} navigation={post.navigation} />
        <div className="container ida-header__content">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-8">
              {post.partner?.member && <div className="img-container">{ImgElement()}</div>}
              {props.windowStore.isSmallerThan('lg') && partner && <PartnerBox partner={partner} />}
              <VisibilitySensor partialVisibility={true} delayedCall={true} offset={{ top: 0, bottom: 0 }}>
                {({ isVisible }) => (
                  <div className="ida-header__partner-map">
                    <PartnerMap partner={post.partner} locations={post.partner_locations} isVisible={isVisible} />
                  </div>
                )}
              </VisibilitySensor>
            </div>
            {props.windowStore.isGreaterEqualThan('lg') && props.windowStore.isSmallerThan('xl') && partner && (
              <div className="col-12 col-lg-6 col-xl-4">
                <PartnerBox partner={partner} />
              </div>
            )}
          </div>
        </div>
      </header>
    );
  })
);
export default HeaderLocation;
