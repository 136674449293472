import React from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../../helpers/api';
import BlockButton from '../../elements/embed/BlockButton';
import UniLink from '../../elements/UniLink/UniLink';

/**
 * returns video embed
 * youtube | vimeo
 * called in ACFEmbedYouTube ACFEmbedVimeo
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#Embed:-Video
 */

const ACFVideoContent = inject('blockDataStore')(
  observer((props) => {
    const default_date = '2020-09-01T00:00:00+02:00';
    const default_title = 'Titel des eingebetteten Videos';
    const default_description = 'Beschreibungstext zum eingebetteten Video';

    return (
      <div className={'mink-video' + props.className}>
        <div className="-shadow">
          <div className="aspect-content">
            <figure
              className={
                'wp-block-embed-' +
                props.provider +
                ' wp-block-embed is-type-video is-provider-' +
                props.provider +
                '  wp-embed-aspect-16-9 wp-has-aspect-ratio'
              }
            >
              <div className="wp-block-embed__wrapper">
                <div className="entry-content-asset" data-img={props.thumbnail}>
                  {!props.allowVideo ? (
                    <BlockButton
                      provider={props.provider}
                      thumbnail={props.thumbnail}
                      onClick={props.blockDataStore.onClickEnableOption}
                    />
                  ) : (
                    renderText(props.iframe)
                  )}
                </div>
              </div>
            </figure>
          </div>
          {props.text || props.title ? (
            <div className="-text-wrapper">
              <div className="-head">{renderText(props.title)}</div>
              <div className="-text">{renderText(props.text)}</div>
            </div>
          ) : null}
          {props.link?.url ? (
            <div className="-link">
              <UniLink href={props.link.url} target={props.link.target}>
                {props.link.title}
              </UniLink>
            </div>
          ) : null}
        </div>

        <script type="application/ld+json" data-gen="dyn">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'VideoObject',
            name: props.title ? props.title : default_title,
            description: props.description ? props.description : default_description,
            thumbnailUrl: [props.thumbnail],
            contentUrl: props.contentUrl,
            embedUrl: props.embedUrl,
            uploadDate: props.date ? props.date : default_date,
          })}
        </script>
      </div>
    );
  })
);

export default ACFVideoContent;
