import React, { useState } from 'react';
import { getCollapseThreshold } from '../../../helpers/api';

/**
 * Toggles the entries configured with the threshold param
 * used in
 * - ACF Partner Locations,
 * - ACF List,
 * - ACF Review Location List
 */

const CollapseToggler = (props) => {
  const [initialItemsLength] = useState(getCollapseThreshold);
  const [toggle, setToggle] = useState(false);

  const handleToggle = (e) => {
    setToggle(!toggle);
  };

  const moreCount = props.moreCount ? props.moreCount : props.total - initialItemsLength;
  const show = 0 < moreCount;
  const toggleClass = toggle ? 'open' : 'closed';
  return (
    <div className="mink-collapse-box__toggler" id={props.id}>
      {show && (
        <button className={'mink-collapse-box__button ' + toggleClass} onClick={handleToggle}>
          {!toggle ? moreCount + ' weitere' : ''}
        </button>
      )}
    </div>
  );
};

export default CollapseToggler;
