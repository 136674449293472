import React from 'react';

const Form = (props) => {
  const { action, ...attribs } = props.attribs;

  return (
    <form action={action} {...attribs}>
      {props.children}
    </form>
  );
};

export default Form;
