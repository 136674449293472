import { action, observable, makeObservable, runInAction } from 'mobx';
import { matchPath } from 'react-router-dom';
import { ROUTES, getRouteByPath } from '../routes/routes';

export default class PagesStore {
  /**
   * @module wpapi
   * wpapi.WPAPI
   */
  api;
  data = {
    posts: {},
    ids: [],
    meta: {},
  };
  isFetched = new Map();
  isFetching = [];
  namespace = 'sanoa';
  currentSlug = false;

  constructor(api, routerStore) {
    this.api = api;
    this.routerStore = routerStore;
    makeObservable(this, {
      isFetched: observable,
      currentSlug: observable,
      fetch: action,
      setCurrentSlug: action,
    });
  }

  getCurrentRoute = () => {
    const store = this.routerStore;
    const routes = ROUTES;
    let match = null;
    for (let i = 0; i < routes.length; i++) {
      match = matchPath(routes[i], store.location.pathname);
      if (match) {
        return routes[i];
      }
    }
    return routes[routes.length - 1];
  };

  setCurrentSlug = (slug) => {
    this.currentSlug = slug;
  };

  /**
   * @todo get rewrite slug from matching route for retrieving custom posttypes, f.e. path: "/videos/:id/"
   * @param {object} params
   */
  fetch(params) {
    // check for object param
    let { path, slug, locationSlug } = params;
    let restBase = '/pages/(?P<id>[\\w-]+)';
    path = !path ? slug : path;
    this.isFetching[path] = true;
    this.api.page = this.api.registerRoute(this.namespace + '/v1', restBase, { params: ['param'] });
    const fetchParams = {};
    if (locationSlug) {
      fetchParams.location = locationSlug;
    }
    this.api
      .page()
      .param(fetchParams)
      .id(slug)
      .then((post) => {
        if (post && post.hasOwnProperty('ID')) {
          this.data.posts[path] = post;
        } else {
          this.data.posts[path] = false;
        }
        runInAction(() => {
          this.isFetched.set(path, true);
        });
        this.isFetching[path] = false;
      })
      .catch((error) => {
        console.error(error);
        this.data.posts[path] = false;
        this.isFetched.set(path, true);
        this.isFetching[path] = false;
      });
  }

  fetchIfNeeded(slug, location = '') {
    const key = '' !== location ? location + '-' + slug : slug;
    if (!this.isFetching[key] && !this.isFetched.get(key)) {
      this.fetch({ slug: slug, locationSlug: location });
    }
  }

  fetchPathIfNeeded(path) {
    if (!this.isFetching[path] && !this.isFetched.get(path)) {
      const route = getRouteByPath(path);
      const match = route && matchPath(route, path);
      if (match) {
        const params = {
          path: path,
          slug: match.params.id,
          postType: route.apiParams.postType,
        };
        this.fetch(params);
      }
    }
  }
}
