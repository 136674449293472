import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const hidePoiStyle = [
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

const currentIcon = {
  path: 'M360.9 175.6C360.9 78.6 280.1 0 180.5 0S.1 78.6.1 175.6c0 3-.2 14.7 0 17.6 4.3 74.8 100.3 213 147.5 278.3 16.6 23 30.2 35.4 32.9 35.4s15.4-12.4 31.2-35.4c47-68.6 141.9-199.8 149.2-278.3.3-2.9 0-14.6 0-17.6zm-71.1 83.2H210V180h-58v78.8H74.5V168l107.6-61.3L289.8 168v90.8zm30-120.6-9.1 15.7c-2.3 4.1-7.5 5.5-11.6 3.1L181.4 88.8 62.8 157.5c-4.1 2.4-9.3 1-11.6-3.1l-9-15.7c-2.3-4.1-.9-9.2 3.1-11.6l132.1-76.5c3.2-1.9 7.1-1.4 9.8.9l129.7 75.1c3.9 2.3 5.3 7.5 2.9 11.6z',
  fillColor: '#dd0000',
  strokeColor: '#660000',
  fillOpacity: 1,
  anchor: { x: 384 / 2, y: 512 },
  strokeWeight: 0,
  scale: 0.075,
  labelOrigin: { x: 384 / 2, y: 512 + 80 },
};
// FontAwesome fa-location-dot
const placesPath =
  'M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z';

const intToHex = (num) => {
  const cleanedNum = Math.max(0, Math.min(255, Math.round(num)));
  return cleanedNum < 16 ? '0' + cleanedNum.toString(16) : cleanedNum.toString(16);
};
const shadeHexColor = (color, factor) => {
  if (typeof color !== 'string' || color.substring(0, 1) !== '#') {
    return color;
  }

  let r;
  let g;
  let b;

  if (color.length === 4) {
    r = parseInt(color.substring(1, 2) + color.substring(1, 2), 16);
    g = parseInt(color.substring(2, 3) + color.substring(2, 3), 16);
    b = parseInt(color.substring(3, 4) + color.substring(3, 4), 16);
  } else if (color.length === 7) {
    r = parseInt(color.substring(1, 3), 16);
    g = parseInt(color.substring(3, 5), 16);
    b = parseInt(color.substring(5, 7), 16);
  } else {
    return color;
  }

  const rHex = intToHex(factor * r);
  const gHex = intToHex(factor * g);
  const bHex = intToHex(factor * b);

  return '#' + rHex + gHex + bHex;
};

export const getIcon = (color, sizeFactor, isPlacesMarker = false) => {
  const styledIcon = { ...currentIcon };

  if (color) {
    styledIcon.fillColor = color;
    styledIcon.strokeColor = shadeHexColor(color, 0.4);
  }
  if (sizeFactor) {
    styledIcon.scale = styledIcon.scale * sizeFactor;
  }
  if (isPlacesMarker) {
    styledIcon.path = placesPath;
  }
  return styledIcon;
};
const libraries = ['places'];

/* expects prop isVisible from VisibilitySensor */
const StyledLocatorMap = inject('blockDataStore')(
  observer(({ isVisible, ...props }) => {
    const getKey = () => {
      if (typeof window !== 'undefined' && typeof window.SN !== 'undefined' && window.SN.gapi) {
        return window.atob(window.SN.gapi);
      } else {
        return false;
      }
    };
    const apiKey = useRef(getKey());
    const [show, setShow] = useState(isVisible);
    const mapRef = useRef(false);

    useEffect(() => {
      if (!show && isVisible) {
        setShow(true);
      }
    }, [show, isVisible]);
    if (!apiKey.current) {
      return null;
    }
    const { isLoaded } = useJsApiLoader(
      {
        id: 'google-map-script',
        googleMapsApiKey: apiKey.current,
        libraries: libraries,
        // preventGoogleFontsLoading: true,
      },
      [libraries]
    );

    if (props.isVisible || show) {
      const propsStyles = props.options?.styles ? props.options.styles : [];

      return isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={props.zoom}
          clickableIcons={false}
          {...props}
          options={{
            ...props.options,
            styles: [...hidePoiStyle, ...propsStyles],
          }}
          onLoad={(map) => {
            mapRef.current = map;
            if (props.mapRef) {
              props.mapRef.current = map;
            }
          }}
          onClick={(e) => {
            if (props.onClick) {
              props.onClick(e);
            }
          }}
          onBoundsChanged={() => {
            if (props.onBoundsChanged) {
              props.onBoundsChanged(mapRef);
            }
          }}
          onZoomChanged={() => {
            if (props.onZoomChanged) {
              props.onZoomChanged(mapRef);
            }
          }}
          onUnmount={(map) => {
            // do your stuff before map is unmounted
          }}
        >
          {props.children}
        </GoogleMap>
      ) : (
        <div>&nbsp;</div>
      );
    } else {
      return <div>&nbsp;</div>;
    }
  })
);

export default StyledLocatorMap;
