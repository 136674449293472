const isSafari = () => {
  const vendor = navigator.vendor;
  const uA = navigator.userAgent;
  return /Safari/i.test(uA) && /Apple Computer/.test(vendor);
};

const isMobile = () => {
  const uA = navigator.userAgent;
  return /Mobi|Android/i.test(uA);
};

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    var s;
    s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });
};
export { isSafari, isMobile, loadScript };
