import React from 'react';

const BlockButton = ({ provider, thumbnail, onClick }) => {
  const getContent = () => {
    let content = '';
    if ('vimeo' === provider) {
      content = (
        <div className="-blocker-text">
          Mit dem Laden des Videos akzeptieren Sie die
          <br />
          <a href="https://vimeo.com/privacy" target="_blank" rel="nofollow noopener noreferrer">
            Datenschutzerklärung
          </a>{' '}
          von Vimeo.
        </div>
      );
    } else {
      content = (
        <div className="-blocker-text">
          Mit dem Laden des Videos akzeptieren Sie die
          <br />
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="nofollow noopener noreferrer">
            Datenschutzerklärung
          </a>{' '}
          von YouTube.
        </div>
      );
    }
    return content;
  };

  const style = thumbnail
    ? {
        backgroundImage: `url(${thumbnail})`,
      }
    : {};
  const option = 'vimeo' === provider ? 'allowVimeo' : 'allowYoutube';
  const btnTxt = 'vimeo' === provider ? 'Vimeo Video laden' : 'YouTube Video laden';

  return (
    <div className={'iframe-block youtube'} style={style}>
      <div className={'iframe-block__caption'}>
        <button className={'iframe-block__button'} onClick={(e) => onClick(option, e)}>
          <span className="-text">{btnTxt}</span>
        </button>
        {getContent()}
      </div>
    </div>
  );
};
export default BlockButton;
