import React from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';

/**
 * vimeo iframe component with blocker for iframe replacement in react html parser
 * for wp-embeded vimeo videos
 * @see renderText
 * @see parserOptions
 */

const VimeoIFrame = inject('blockDataStore')(
  observer((props) => {
    const allowVimeo = props.blockDataStore.allowVimeo;
    if (!allowVimeo) {
      return (
        <BlockButton provider="vimeo" thumbnail={props.thumbnail} onClick={props.blockDataStore.onClickEnableOption} />
      );
    } else {
      const { title, frameborder, allowfullscreen, ...attribs } = props.attribs;
      return <iframe title={title} frameBorder={frameborder} allowFullScreen={allowfullscreen} {...attribs}></iframe>;
    }
  })
);

export default VimeoIFrame;
