import React from 'react';
import { inject, observer } from 'mobx-react';
import UniLink from '../../elements/UniLink/UniLink';

/**
 * generates a on page menu
 * option one: generate automatically nav items from found headline elements
 * option two: output manually created items
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#Hash-Menu
 */

const ACFOnPageNav = inject('onPageNavStore')(
  observer((props) => {
    const content = props.content;
    const genericItems = [];
    if (content.mink_fc_onpagenav_generic) {
      props.onPageNavStore.items.forEach((item) => {
        genericItems.push(item);
      });
    }
    const navItems = content.mink_fc_onpagenav_generic ? genericItems : content.mink_fc_onpagenav_items;

    return navItems ? (
      <div className="mink-hash-menu">
        {content.mink_fc_onpagenav_title ? (
          <div className="mink-hash-menu__header">
            <h3 className="-headline">{content.mink_fc_onpagenav_title}</h3>
          </div>
        ) : null}
        <div className="mink-hash-menu__items">
          <ul>
            {navItems.length &&
              navItems.map((navItem, i) => {
                return (
                  <li className="mink-hash-menu_item -item -item-1" key={i}>
                    <UniLink
                      className="-link"
                      href={navItem.mink_fc_onpagenav_item.url}
                      target={navItem.mink_fc_onpagenav_item.target}
                    >
                      {navItem.mink_fc_onpagenav_item.title}
                    </UniLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    ) : null;
  })
);

export default ACFOnPageNav;
