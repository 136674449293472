import React from 'react';
import { observer, inject } from 'mobx-react';
import { trackingClassName } from '../../../helpers/api';
import PartnerThirdPartyContainer from '../../partner/PartnerThirdPartyContainer/PartnerThirdPartyContainer';

const Footer = inject('phoneStore')(
  observer((props) => {
    const post = props.post;
    const trackingParams = {
      partnerSlug: post.partner.slug,
      postType: post.post_type,
      slug: post.slug,
    };

    return (
      <footer className="mink-footer">
        <PartnerThirdPartyContainer
          partner={post.partner}
          className={trackingClassName(trackingParams, 'footer-third-party')}
        />
      </footer>
    );
  })
);

export default Footer;
