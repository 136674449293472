import React from 'react';
import { Link } from 'react-router-dom';
import { parseUrlComponents } from '../../../helpers/api';

const UniLink = (props) => {
  const { title, href, target, className, children, ...attribs } = props;
  const urlComponents = parseUrlComponents(href);
  const hostname = window?.location.hostname;

  if (hostname === urlComponents.hostname && !target) {
    return (
      <Link
        className={className ? className : undefined}
        to={{ pathname: urlComponents.pathname, search: urlComponents.search, hash: urlComponents.hash }}
        title={title}
        {...attribs}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={className ? className : undefined}
        href={href}
        target={target ? target : undefined}
        title={title}
        {...attribs}
        rel={target ? 'noopener noreferrer' : attribs.rel}
      >
        {children}
      </a>
    );
  }
};

export default UniLink;
