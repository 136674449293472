import './helpers/polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
//import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'mobx-react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { default as stores } from './stores/index';
import { ROUTES as routes } from './routes/routes';

const prepareRoutes = (routes, isRoot = false) => {
  if (isRoot) {
    return [
      {
        path: '/',
        element: <App key="/" />,
        children: prepareRoutes(routes),
      },
    ];
  }
  // path="*" element={<App />}
  return routes.map((route) => {
    if (route.children) {
      route.children = prepareRoutes(route.children);
    }

    route.element = (
      <route.component
        //match={match}
        //location={location}
        key={route.path}
        routes={route.routes}
        path={route.path}
        apiParams={route.apiParams}
      />
    );
    return route;
  });
};

const preparedRoutes = prepareRoutes(routes, true);
const router = createBrowserRouter(preparedRoutes);

ReactDOM.render(
  <Provider {...stores}>
    <RouterProvider router={router} />
  </Provider>,
  document.getElementById('app_root')
);
/*const container = document.getElementById('app_root');
const root = createRoot(container);
root.render(
  <Provider {...stores}>
    <RouterProvider router={router} />
  </Provider>
);*/

unregister();
