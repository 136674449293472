import React, { useState } from 'react';
import { renderText } from '../../../helpers/api';
import Img from '../../elements/Img/Img';

const Layover = (props) => {
  // const onCloseInfo = props.onCloseInfo;
  const location = props.location;
  const brands = props.brands;
  const services = props.services;
  const [servicesToggle, setServicesToggle] = useState(false);
  const filter = props.filter;
  const serviceListMax = 5;

  // if filter is matching we show location button and fallback image on non partners
  // but we hide services
  let filteredBrands = false;
  // if only one filter is set, we apply the link to the location button
  // let locationButtonLink = '';
  location.brands.map((termId, i) => {
    if (filter.brands.includes(termId)) {
      filteredBrands = true;
    }
    return null;
  });
  let filteredServices = false;
  location.services.map((termId, i) => {
    if (filter.services.includes(termId)) {
      filteredServices = true;
    }
    return null;
  });
  const getImg = () => {
    if (location.post_thumbnail?.small && location.post_thumbnail?.small[0]) {
      return (
        <Img
          src={location.post_thumbnail.small[0]}
          width={location.post_thumbnail.small[1]}
          height={location.post_thumbnail.small[2]}
          alt={'Bild von ' + location?.title + ' in ' + location?.address?.city}
          title={'Bild von ' + location?.title + ' in ' + location?.address?.city}
        />
      );
    }
    return null;
  };
  const getServicesList = () => {
    if (location.services && location.services.length) {
      const list = location.services.map((termId, i) => {
        if (filteredServices) {
          if (filter.services.includes(termId)) {
            // locationButtonLink = `${location.url}service/${services[termId].slug}/`;
            return (
              <div className="location-service" key={services[termId].slug}>
                <a
                  href={`${location.url}service/${services[termId].slug}/`}
                  className={'mink-btn-colored'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {services[termId].name}
                </a>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return (
            services[termId] && (
              <div className="location-service" key={services[termId].slug}>
                {services[termId].name}
              </div>
            )
          );
        }
      });
      return list;
    }
    return null;
  };
  const getBrandList = () => {
    if (location.brands && location.brands.length) {
      const logos = location.brands.map((termId, i) => {
        if (filter.brands.includes(termId)) {
          return (
            <div className="location-brand-link" key={brands[termId].slug}>
              <a
                href={`${location.url}marke/${brands[termId].slug}/`}
                className="mink-btn-colored"
                style={{
                  backgroundColor: brands[termId].brand_primary_color,
                  border: '0.125rem solid ' + brands[termId].brand_primary_color,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {brands[termId].title}
              </a>
            </div>
          );
        } else {
          return null;
        }
      });
      return logos;
    }
    return null;
  };
  const getFormatedOpeningHours = (formatedOpeningHours) => {
    if (formatedOpeningHours) {
      const today = new Date();
      const todayWeekday = today.toLocaleDateString('de-DE', { weekday: 'long' });
      return Object.keys(formatedOpeningHours).map((dayName, index) => {
        const hours = formatedOpeningHours[dayName];
        if (todayWeekday !== dayName) {
          return null;
        }
        let text = '';
        if (true === hours.isSpecial) {
          text = renderText('Heute ' + hours.lines.slice(1).join(`<br>`));
        } else {
          text = `Heute ${renderText(hours.lines.join(`<br>`))}`;
          text += -1 === text.indexOf('Geschlossen') ? ` geöffnet` : '';
        }
        return (
          <div className={'row location-opening-hours font-weight-bold current-day'} key={dayName}>
            <div className={'col-8'}>{text}</div>
          </div>
        );
      });
    }
    return null;
  };
  const img = getImg();
  const servicesList = getServicesList();
  const brandList = getBrandList();

  return (
    <div className="layover-content">
      {location.member ? (
        <div className="location-thumb">
          <a className="img-container" href={location.url} target="_blank" rel="noopener noreferrer">
            {img}
          </a>
        </div>
      ) : (
        <>
          <div className="location-thumb">
            <div className="img-container">
              <Img
                src={'/content/themes/sanoa/assets/images/sanoa-fallback.webp'}
                width={300}
                height={300}
                alt={'Sanoa Partner werden'}
              />
            </div>
          </div>
        </>
      )}

      <div className={location.member ? 'location-detail' : 'location-detail non-partner'}>
        {location.url ? (
          <p className="location-detail-head">
            <a
              href={location.url}
              title={'Zur Standortseite ' + location?.title + ' in ' + location?.address?.city}
              target="_blank"
              rel="noopener noreferrer"
            >
              {renderText(location.title)}
            </a>
          </p>
        ) : (
          <p className="location-detail-head">{renderText(location.title)}</p>
        )}

        <p className="location-address">
          {location.address.street} {location.address.street_number}
          <br />
          {location.address.zip} {location.address.city}
        </p>
        {location.phone && (
          <p className="location-phone">
            Telefon <a href={'tel:' + location.phone}>{location.phone}</a>
          </p>
        )}
        {location.formatedOpeningHours && getFormatedOpeningHours(location.formatedOpeningHours)}
        {!filteredBrands && servicesList && (
          <>
            <p className="location-service-head">Wir helfen Ihnen:</p>
            {!filteredServices ? (
              <>
                <div className={'location-services-list' + (servicesToggle ? ' -active' : '')}>
                  {servicesToggle ? servicesList : servicesList.slice(0, serviceListMax)}
                  {!servicesToggle && servicesList.length > serviceListMax && <span>&nbsp;...</span>}
                </div>
                {servicesList.length > serviceListMax && (
                  <div
                    className={'location-services__toggle' + (servicesToggle ? ' open' : '')}
                    onClick={() => setServicesToggle((prevState) => !prevState)}
                  >
                    <span className="-more">mehr anzeigen</span>
                    <span className="-less">weniger anzeigen</span>
                  </div>
                )}
              </>
            ) : (
              <div className={'location-services-list'}>{servicesList}</div>
            )}
          </>
        )}
        {brandList && filteredBrands && (
          <>
            <p className="location-brand-head">Am Standort:</p>
            <div className="location-brand-list">{brandList}</div>
          </>
        )}
        {location.url && location.member && (
          <>
            {!filteredServices && !filteredBrands && (
              <p className="location-button">
                <a
                  href={location.url}
                  title={'Zur Standortseite ' + location?.title + ' in ' + location?.address?.city}
                  className="mink-btn-colored"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zum Standort
                </a>
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Layover;
