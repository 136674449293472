import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './ACFAppointmentIframeBlockButton';
import ACFCtaButton from '../ACFCtaButton/ACFCtaButton';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import { trackingClassName } from '../../../helpers/api';

/**
 * squarespace appointment iframe
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/262897672/Terminbuchung+online
 */

const ACFAppointmentIframe = inject('blockDataStore')(
  observer((props) => {
    const [isOpenIframCollapse, setIsOpenIframCollapse] = useState(false);
    const [isIframeTooHigh, setIsIframeTooHeight] = useState(false);
    const iframeRef = useRef();
    const isInitialized = useRef(false);
    const content = props.content;
    const post = props.post;
    const postType = post.post_type;
    const ctaContent = { mink_fc_cta_type: 'location', mink_fc_cta_text: 'Termin vereinbaren' };
    const trackingParams = {
      partnerSlug: post.partner.slug,
      postType: post.post_type,
      slug: post.slug,
    };

    // generate prefilled fallback maillink, if no appointment is available
    let subject = '';
    if ('sanoa_service' === postType) {
      subject = 'Anfrage von der Internetseite (Sanoa): ' + post?.location.title;
    } else if ('sanoa_brand' === postType) {
      subject = 'Anfrage von der Internetseite (Sanoa): ' + post?.location.title;
    } else {
      subject = 'Anfrage von der Internetseite (Sanoa): ' + post?.location.title;
    }

    const body =
      'Anfrage von Webseite (URL): ' +
      post.permalink +
      '\n\nRückrufwunsch von (Name): ' +
      '\n\nTelefonnummer: ' +
      '\n\nZusätzliche Nachricht: ';
    const mailto = encodeURI('mailto:' + post.partner.email + '?subject=' + subject + '&body=' + body);
    const handleIframeCollapse = () => {
      setIsOpenIframCollapse(!isOpenIframCollapse);
    };
    useEffect(() => {
      if (content.iframeURL) {
        const iframe = iframeRef.current;
        const handleMessage = (e) => {
          // message that was passed from iframe page
          if (e.origin === 'https://app.squarespacescheduling.com') {
            const iframeHeightData = e.data.split(':')[1];
            const iframeHeightDataPrefix = e.data.split(':')[0];
            if ('sizing' === iframeHeightDataPrefix && 800 < parseInt(iframeHeightData)) {
              setIsIframeTooHeight(true);
            }
          }
        };

        if (iframe) {
          window.addEventListener('message', handleMessage, false);
        }
        return () => window.removeEventListener('message', handleMessage, false);
      }
    }, [content.iframeURL]);
    useEffect(() => {
      if (content.iframeURL) {
        const initIframe = () => {
          const script = document.createElement('script');
          script.async = true;
          script.src = 'https://embed.acuityscheduling.com/js/embed.js';
          script.type = 'text/javascript';
          script.addEventListener('load', () => {
            isInitialized.current = true;
          });

          if (iframeRef.current) {
            iframeRef.current.appendChild(script);
          }
        };
        if (props.blockDataStore.allowAppointments && !isInitialized.current) {
          initIframe();
        }
      }
    }, [isInitialized, props.blockDataStore.allowAppointments, iframeRef, content.iframeURL]);

    if (content.iframeURL) {
      const allowAppointments = props.blockDataStore.allowAppointments;

      if (!allowAppointments) {
        return (
          <BlockButton
            height={500}
            provider="Squarespace"
            allowValue="allowAppointments"
            privacyLink="https://de.squarespace.com/datenschutz"
            onClick={props.blockDataStore.onClickEnableOption}
            trackingClass={trackingClassName(trackingParams, 'appointment-block')}
          />
        );
      } else {
        const attribs = attributesToProps(props.attribs);
        attribs.className = 'mink-iframe';
        const iframeCollapseClassSuffix =
          isIframeTooHigh && !isOpenIframCollapse
            ? '-close mb-5'
            : isIframeTooHigh && isOpenIframCollapse
            ? '-open'
            : '';

        return (
          <div key={attribs.id} {...attribs}>
            <iframe
              className={'mink-iframe__collapse ' + iframeCollapseClassSuffix}
              ref={iframeRef}
              src={content.iframeURL}
              title="Termin vereinbaren"
              width="100%"
              height="800"
              frameBorder="0"
            ></iframe>
            {isIframeTooHigh && !isOpenIframCollapse ? <div className="iframe-collapse__gradient"></div> : null}
            {isIframeTooHigh ? (
              <div className="mink-cta-button text-center">
                <button onClick={() => handleIframeCollapse()} className="btn-cta w-auto">
                  {isOpenIframCollapse ? 'Schliessen' : 'Alle Kalender anzeigen'}
                </button>
              </div>
            ) : null}
          </div>
        );
      }
    }

    return (
      <div className="mink-appointment-fallback">
        <ACFCtaButton
          content={ctaContent}
          partner={post.partner}
          location={post?.location}
          post_type={post.post_type}
          slug={post.slug}
        >
          {{
            props: {
              content: ctaContent,
              partner: post.partner,
              location: post?.location,
              post_type: post.post_type,
              slug: post.slug,
            },
          }}
        </ACFCtaButton>
        <div className="mink-appointment-fallback__text">
          Oder Sie schreiben eine kurze E-Mail mit Rückrufwusch:{' '}
          <a href={mailto} className={trackingClassName(trackingParams, 'appointment-fallback')}>
            {post.partner.email}
          </a>
        </div>
      </div>
    );
  })
);

export default ACFAppointmentIframe;
