import React from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../../helpers/api';
import UniLink from '../../elements/UniLink/UniLink';
import Img from '../../elements/Img/Img';

/**
 * ACF Teaser with Image, Text and Link
 */

const ACFTeaser = inject('windowStore')(
  observer((props) => {
    const windowStore = props.windowStore;
    const smallImage = windowStore.isSmallerThan('sm');

    const TeaserImg = () => {
      return (
        <>
          {smallImage ? (
            <Img
              src={props.content.mink_fc_teaser_img?.sizes?.small}
              width={props.content.mink_fc_teaser_img?.sizes?.smallWidth}
              height={props.content.mink_fc_teaser_img?.sizes?.smallHeight}
              alt={props.content.mink_fc_teaser_img?.alt}
              title={props.content.mink_fc_teaser_img?.title}
            />
          ) : (
            <Img
              src={props.content.mink_fc_teaser_img?.sizes?.medium}
              width={props.content.mink_fc_teaser_img?.sizes?.mediumWidth}
              height={props.content.mink_fc_teaser_img?.sizes?.mediumHeight}
              title={props.content.mink_fc_teaser_img?.title}
            />
          )}
        </>
      );
    };
    const teaserText = props.content.mink_fc_teaser_text;
    const teaserLink = props.content.mink_fc_teaser_link;

    return (
      <div className="acf-teaser">
        <div className="acf-teaser__img">
          {teaserLink.url ? (
            <UniLink href={teaserLink.url} target={teaserLink.target}>
              <TeaserImg />
            </UniLink>
          ) : (
            <TeaserImg />
          )}
        </div>
        <div className="acf-teaser__text">
          {renderText(teaserText)}
          {teaserLink.url && (
            <div className="-link-wrapper">
              <div className="-link">
                <UniLink href={teaserLink.url} target={teaserLink.target}>
                  {renderText(teaserLink.title)}
                </UniLink>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  })
);

export default ACFTeaser;
