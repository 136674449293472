import React, { useState, useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { WINDOW_EXISTS } from '../../../helpers/viewport';
import { RouterContext } from '../../../stores/RouterContext';

const ScrollToHash = inject('scrollStore')(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const location = routerContext.location;
    const [shouldScroll] = useState(!props.onHistoryPush || 'PUSH' === routerContext.navigationType);
    const verticalOffset = props.offset ? props.offset : 0;

    useEffect(() => {
      if (location.hash && shouldScroll && WINDOW_EXISTS) {
        setTimeout(() => {
          const el = document.getElementById(location.hash.replace(/^#/, ''));
          if (el) {
            WINDOW_EXISTS && window.scrollTo(0, 0);
            props.scrollStore.animateScrollTo({ desiredOffset: el, verticalOffset: verticalOffset });
          }
        }, 5);
      }
    }, [shouldScroll, props.scrollStore, location.hash, verticalOffset]);

    return <div className="d-none" data-hash={location.hash}></div>;
  })
);

export default ScrollToHash;
