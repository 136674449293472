import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import BlockButton from './BlockButton';

const PartnerThirdPartyContainer = observer(({ partner, className }) => {
  const { partnerStore, blockDataStore, windowStore } = useContext(MobXProviderContext);
  const allowThirdPartyApp = blockDataStore.allowThirdPartyApp;
  if (!partner?.third_party_app || true !== partner?.third_party_app) {
    return null;
  }

  if (!partnerStore.extScriptLoaded && allowThirdPartyApp) {
    partnerStore.loadExtScript(partner.slug);
  }

  return (
    <>
      {allowThirdPartyApp ? null : (
        <BlockButton
          className={className}
          text={partner?.third_party_text}
          onClick={blockDataStore.onClickEnableOption}
          isMobile={windowStore.isSmallerThan('md')}
        />
      )}
    </>
  );
});

export default PartnerThirdPartyContainer;
