import React, { useRef, useEffect } from 'react';

/**
 * iframe component iframe replacement in react html parser
 * for wp-embeded cf7 forms
 * @see renderText
 * @see parserOptions
 */
const FormIFrame = (props) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const handleMessage = (e) => {
      // message that was passed from iframe page
      const message = e.data;
      iframe.style.height = message.height + 'px';
    };
    if (iframe) {
      window.addEventListener('message', handleMessage, false);
    }
    return () => window.removeEventListener('message', handleMessage, false);
  }, []);

  return (
    <>
      <iframe title="Form" ref={iframeRef} {...props} />
    </>
  );
};

export default FormIFrame;
