import React, { useEffect, useCallback, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { entries } from 'mobx';
import { renderText } from '../../../helpers/api';
import { MarkerF, MarkerClustererF } from '@react-google-maps/api';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import VisibilitySensor from '@zelty/react-visibility-sensor';
import StyledLocatorMap, { getIcon } from './StyledLocatorMap';
import LocationsList from './LocationsList';
import Layover from './Layover';
import LoadingAnimation from '../../nav/LoadingAnimation/LoadingAnimation';
import BlockButton from './BlockButton';
import { getNewLocatorStore } from '../../../stores';

/**
 * ACF Flexible Content Field
 * embeds the google maps
 * fetches the locations from WP REST API
 * @field mink_fc_locator_map
 */
const ACFLocatorMap = inject('blockDataStore')(
  observer((props) => {
    const storeRef = useRef(null);
    if (!storeRef.current) {
      storeRef.current = getNewLocatorStore(props._key);
    }
    const store = storeRef.current;
    const configRef = useRef();
    const mapRef = useRef(false);
    if (!configRef.current) {
      configRef.current = store.setSettings(props);
    }
    const selectedLocation = useRef(null);
    const config = configRef.current;
    const [partnersToggle, setPartnersToggle] = useState(false);
    const [brandsToggle, setBrandsToggle] = useState(false);
    const [servicesToggle, setServiceToggle] = useState(false);
    const [mapsToggle, setMapsToggle] = useState(config.start_map_view);
    const [isVisible, setIsVisble] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [zoomedIn, setzoomedIn] = useState(config.settings.zoom < 9 ? false : true);

    const [placesValue, setPlacesValue] = useState(null);
    const [placesPosition, setPlacesPosition] = useState(null);
    const onClickMap = (e) => {
      if (e) {
        setSelectedMarker(null);
      }
    };

    const clickMarker = (location) => {
      selectedLocation.current = location;
      setSelectedMarker(location.slug);
      // mapRef.current.setCenter({ lat: location.address.lat, lng: location.address.lng - 0.8 });
    };
    const onCloseInfo = (e) => {
      setSelectedMarker(null);
      selectedLocation.current = null;
    };

    const onClickSearchBtn = (e) => {
      store.fetchIfNeeded();
    };
    const searchNow = () => {
      store.fetchIfNeeded();
    };

    const onGooglePlacesAutocompleteSelect = () => {
      const map = mapRef.current;
      if (!window?.google?.maps) {
        return;
      }
      // setZoom, fitBounds etc. is async so we set a listender and wait til map is not moving
      const listener = map.addListener('idle', (e) => {
        searchNow();
        window.google.maps.event.removeListener(listener);
      });
    };

    useEffect(() => {
      if (
        (store.mapIsReady || !mapsToggle) &&
        !store.isInitialFetched &&
        !store.isFetching &&
        !config.initial_search_on_demand
      ) {
        store.fetchIfNeeded();
      }
    }, [config.initial_search_on_demand, mapsToggle, store, store.mapIsReady]);

    const onClickCheckbox = useCallback(
      (slug, key) => {
        store.onClickCheckbox(slug, key);
      },
      [store]
    );
    const onChangeVisibility = (isVisible) => {
      setIsVisble(isVisible);
    };
    const allowed = props.blockDataStore.allowGoogleMaps;

    const filteredLocations = store.filteredLocations;
    const showFilters = store.showFilters;
    const partners = Object.values(store.partners).sort((a, b) => a.title?.localeCompare(b.title));
    const brands = entries(store.brands).sort((a, b) => a[1].title?.localeCompare(b[1].title));
    const services = Object.values(store.services).sort((a, b) => a.name?.localeCompare(b.name));
    const formFields = store.formFields;
    const maxWidth = config.width ? config.width + '%' : 100 + '%';
    const startWithSearch = config.initial_search_on_demand && !store.isInitialFetched && !store.isFetching;

    return (
      <VisibilitySensor
        partialVisibility={true}
        delayedCall={true}
        onChange={onChangeVisibility}
        offset={{ top: 0, bottom: 0 }}
      >
        <div
          className={
            'acf-locator' +
            (showFilters ? ' -filter' : '') +
            (config.show_filters.partner ? ' -filter-partner' : '') +
            (config.show_filters.services ? ' -filter-services' : '') +
            (config.show_filters.services ? ' -filter-brands' : '') +
            (config.show_maps_ui ? ' -ui' : '') +
            (startWithSearch ? ' start-with-search' : '')
          }
          style={{ maxWidth: maxWidth }}
        >
          <div className={`acf-locator__panel`}>
            {store.mapIsReady && config.show_search && (
              <>
                <GooglePlacesAutocomplete
                  apiOptions={{ language: 'de', region: 'de' }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ['de'],
                    },
                  }}
                  value={placesValue}
                  debounce={500}
                  selectProps={{
                    autoFocus: config.initial_search_on_demand ? true : false,
                    unstyled: true,
                    className: 'acf-locator__autocomplete',
                    classNames: {
                      control: () => 'autocomplete-control',
                      valueContainer: () => 'autocomplete-container',
                      placeholder: () => 'autocomplete-placeholder',
                      input: () => 'autocomplete-input',
                      menu: () => 'autocomplete-menu',
                      indicatorsContainer: () => 'autocomplete-indicators-container',
                      indicatorSeparator: () => 'autocomplete-indicator-seperator',
                      IndicatorContainer: () => 'autocomplete-indicator-container',
                      svg: () => 'autocomplete-svg',
                    },
                    placeholder: 'Hier einen Ort oder PLZ eingeben',
                    noOptionsMessage: ({ inputValue }) => (!inputValue ? null : 'keine Ergebnisse'),
                    onChange: async (value, actionMeta) => {
                      setPlacesValue(value);
                      const geocoded = await geocodeByPlaceId(value.value.place_id);
                      if (geocoded.length) {
                        const center = await getLatLng(geocoded[0]);
                        setPlacesPosition(center);
                        if (mapRef.current) {
                          await mapRef.current.setCenter(center);
                          await mapRef.current.setZoom(12);
                          onGooglePlacesAutocompleteSelect();
                        }
                      }
                    },
                  }}
                />
                <button
                  className={'acf-locator__toggle-map mink-btn' + (mapsToggle ? ' -active' : '')}
                  onClick={() => setMapsToggle((prevState) => !prevState)}
                >
                  <span className="-map">{renderText('Kartenansicht')}</span>
                  <span className="-list">{renderText('Listenansicht')}</span>
                </button>
              </>
            )}
          </div>
          <div className={'acf-locator__map' + (mapsToggle ? ' -active' : '')}>
            {!allowed ? (
              <BlockButton onClick={props.blockDataStore.onClickEnableOption} />
            ) : (
              <StyledLocatorMap
                isVisible={isVisible}
                zoom={config.settings.zoom}
                center={store.center ? store.center : config.settings.center}
                onClick={onClickMap}
                mapRef={mapRef}
                options={{
                  disableDefaultUI: !config.show_maps_ui,
                  minZoom: 6,
                }}
                onBoundsChanged={(mapRef) => {
                  const bounds = mapRef.current.getBounds().toJSON();
                  const center = mapRef.current.getCenter().toJSON();
                  if (bounds.north !== store.bounds.north || bounds.west !== store.bounds.west) {
                    store.setBounds(bounds);
                    store.center = center;
                  }
                  if (!store.mapIsReady) {
                    store.setMapIsReady(true);
                  }
                }}
                onZoomChanged={(mapRef) => {
                  if (mapRef.current) {
                    const map = mapRef.current;
                    const zoom = map.getZoom();
                    const newZoomedIn = zoom >= 8;
                    if (newZoomedIn !== zoomedIn) {
                      setzoomedIn(newZoomedIn);
                    }
                  }
                }}
              >
                {store.isFetching && <LoadingAnimation />}
                {Array.isArray(filteredLocations) && (
                  <MarkerClustererF
                    options={{
                      imagePath: `${process.env.PUBLIC_URL}/images/markercluster/m`,
                    }}
                    averageCenter={true}
                    minimumClusterSize={2}
                    maxZoom={8}
                    zoomOnClick={false}
                    gridSize={zoomedIn ? 45 : 60}
                    onClick={(cluster) => {
                      // handle this cluster behavior
                      const map = cluster.map;
                      const locations = cluster.getMarkers();
                      const clusterBounds = cluster.getBounds();
                      for (const location of locations) {
                        clusterBounds.extend(location.getPosition());
                      }
                      map.fitBounds(clusterBounds);
                      map.setCenter(clusterBounds.getCenter());
                    }}
                  >
                    {(clusterer) => {
                      // colors: member #19487e, non member #777777
                      const size = 0.5;
                      const icon = {
                        member: {
                          default: {
                            url: `${process.env.PUBLIC_URL}/images/markers/sanitaetshaus-partner-pin.png`,
                            scaledSize: new window.google.maps.Size(48 * size, 72 * size),
                          },
                          selected: {
                            url: `${process.env.PUBLIC_URL}/images/markers/sanitaetshaus-partner-pin.png`,
                            scaledSize: new window.google.maps.Size(48 * size * 1.3, 72 * size * 1.3),
                          },
                        },
                        nonMember: {
                          default: {
                            url: `${process.env.PUBLIC_URL}/images/markers/sanitaetshaus-online-finden-grau-pin.png`,
                            scaledSize: new window.google.maps.Size(36 * size, 54 * size),
                          },
                          selected: {
                            url: `${process.env.PUBLIC_URL}/images/markers/sanitaetshaus-online-finden-grau-pin.png`,
                            scaledSize: new window.google.maps.Size(36 * size * 1.3, 54 * size * 1.3),
                          },
                        },
                      };
                      return filteredLocations.map((location) => {
                        const zIndex = false === location.member ? 1 : 2;
                        // const icon = false === location.member ? iconNonMember : iconMember;
                        let pinIcon = icon.nonMember.default;
                        if (false === location.member) {
                          if (location.slug === selectedMarker) {
                            pinIcon = icon.nonMember.selected;
                          }
                        } else {
                          pinIcon = icon.member.default;
                          if (location.slug === selectedMarker) {
                            pinIcon = icon.member.selected;
                          }
                        }

                        // logic for extra marker for filtered brands
                        if (
                          store.activeFilterIds?.brands &&
                          store.activeFilterIds?.brands.length &&
                          store.allBrands[store.activeFilterIds.brands[0]]
                        ) {
                          const brandIcon = {
                            default: {
                              url: `${process.env.PUBLIC_URL}/images/markers/${
                                store.allBrands[store.activeFilterIds.brands[0]].term_slug
                              }.png`,
                              scaledSize: new window.google.maps.Size(36 * size, 54 * size),
                            },
                            selected: {
                              url: `${process.env.PUBLIC_URL}/images/markers/${
                                store.allBrands[store.activeFilterIds.brands[0]].term_slug
                              }.png`,
                              scaledSize: new window.google.maps.Size(36 * size * 1.3, 54 * size * 1.3),
                            },
                          };
                          pinIcon = brandIcon.default;
                          if (location.slug === selectedMarker) {
                            pinIcon = brandIcon.selected;
                          }
                        }

                        return (
                          <MarkerF
                            key={location.slug + '' + pinIcon.url + pinIcon.scaledSize.toString()}
                            clusterer={clusterer}
                            position={{ lat: location.address.lat, lng: location.address.lng }}
                            icon={pinIcon}
                            zIndex={zIndex}
                            title={location.title}
                            onClick={() => {
                              return clickMarker(location);
                            }}
                            onLoad={(marker) => {
                              store.markers[location.slug] = marker;
                            }}
                          >
                            {/* {selectedMarker && selectedMarker === location.slug && (
                              <InfoWindow
                                key={location.slug}
                                location={location}
                                onCloseInfo={onCloseInfo}
                                brands={store.allBrands}
                                services={store.allServices}
                                filter={store.activeFilterIds}
                              />
                            )} */}
                          </MarkerF>
                        );
                      });
                    }}
                  </MarkerClustererF>
                )}
                {placesPosition && (
                  <MarkerF key={'places'} icon={getIcon('#3ab46c', 0.75, true)} position={placesPosition}></MarkerF>
                )}
              </StyledLocatorMap>
            )}
            {store.canSearch && (
              <div className="acf-locator__search-here">
                <button className="mink-btn" onClick={onClickSearchBtn}>
                  {renderText('In diesem Gebiet suchen')}
                </button>
              </div>
            )}
          </div>
          <div className={'acf-locator__list' + (mapsToggle ? '' : ' -active')}>
            {store.isFetching && <LoadingAnimation />}
            <LocationsList
              locations={filteredLocations}
              onMouseEnterLocation={store.onMouseEnterLocation}
              onMouseLeaveLocation={store.onMouseLeaveLocation}
            />
          </div>
          {selectedMarker && selectedLocation.current && (
            <div className={'acf-locator__layover -active'}>
              <div className="layover-close" onClick={onCloseInfo}>
                schliessen
              </div>
              <Layover
                key={selectedLocation.current.slug}
                location={selectedLocation.current}
                onCloseInfo={onCloseInfo}
                brands={store.allBrands}
                services={store.allServices}
                filter={store.activeFilterIds}
              />
            </div>
          )}
          {showFilters ? (
            <div className="acf-locator__filter">
              <div className="-caption">
                <strong>Ergebnisse filtern:</strong>
              </div>
              {config.show_filters.partner && partners && partners.length ? (
                <div className="-item">
                  <div className="mink-dropdown">
                    <div
                      className={'mink-dropdown__toggle' + (partnersToggle ? ' -active' : '')}
                      onClick={() => setPartnersToggle((prevState) => !prevState)}
                    >
                      Sanitätshaus
                    </div>
                    <div className={'mink-dropdown__container' + (partnersToggle ? ' -active' : '')}>
                      {partners.map((partner) => {
                        return (
                          <div
                            className={'mink-dropdown__item' + (formFields.partners[partner.slug] ? ' -active' : '')}
                            key={partner.slug}
                          >
                            <input
                              name="partners[]"
                              id={partner.slug}
                              onChange={(e) => {
                                onClickCheckbox(partner.slug, 'partners');
                              }}
                              checked={formFields.partners[partner.slug]}
                              disabled={!!store.config.partner?.slug}
                              type="checkbox"
                            />
                            <label htmlFor={partner.slug}>
                              <span>{renderText(partner.title)}</span>
                              {partner.logoUrl && (
                                <img
                                  width={partner?.logoWidth}
                                  height={partner?.logoHeight}
                                  src={partner.logoUrl}
                                  alt={partner?.logoAlt}
                                  title={partner.title}
                                />
                              )}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {config.show_filters.brands && brands && brands.length ? (
                <div className="-item">
                  <div className="mink-dropdown">
                    <div
                      className={'mink-dropdown__toggle' + (brandsToggle ? ' -active' : '')}
                      onClick={() => setBrandsToggle((prevState) => !prevState)}
                    >
                      Hersteller
                    </div>
                    <div className={'mink-dropdown__container' + (brandsToggle ? ' -active' : '')}>
                      {brands.map((brand, i) => {
                        return (
                          <div
                            className={'mink-dropdown__item' + (formFields.brands[brand[1].slug] ? ' -active' : '')}
                            key={brand[1].slug}
                          >
                            <input
                              onChange={(e) => {
                                onClickCheckbox(brand, 'brands');
                              }}
                              name="brands[]"
                              id={brand[1].slug}
                              checked={formFields.brands[brand[1].slug]}
                              type="checkbox"
                            />

                            <label htmlFor={brand[1].slug}>
                              <span>{renderText(brand[1].title)}</span>
                              {brand[1].logo && (
                                <img
                                  width={brand[1].logo.width}
                                  height={brand[1].logo.height}
                                  src={brand[1].logo.url}
                                  alt={brand[1].logo.alt}
                                  title={brand[1].title}
                                />
                              )}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {config.show_filters.services && services && services.length ? (
                <div className="-item">
                  <div className="mink-dropdown">
                    <div
                      className={'mink-dropdown__toggle' + (servicesToggle ? ' -active' : '')}
                      onClick={() => setServiceToggle((prevState) => !prevState)}
                    >
                      Thema
                    </div>
                    <div className={'mink-dropdown__container' + (servicesToggle ? ' -active' : '')}>
                      {services.map((services, i) => {
                        return (
                          <div
                            className={'mink-dropdown__item' + (formFields.services[services.slug] ? ' -active' : '')}
                            key={services.slug + i}
                          >
                            <input
                              onChange={(e) => {
                                onClickCheckbox(services.slug, 'services');
                              }}
                              name="services[]"
                              id={services.slug + i}
                              checked={formFields.services[services.slug]}
                              type="checkbox"
                            />
                            <label htmlFor={services.slug + i}>
                              <span>{renderText(services.title ? services.title : services.name)}</span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </VisibilitySensor>
    );
  })
);

export default ACFLocatorMap;
