import React, { Suspense } from 'react';
import { inject, observer } from 'mobx-react';
import { trackingClassName, renderText } from '../../../helpers/api';
import Img from '../../elements/Img/Img';
import LocationBox from '../../location/LocationBox/LocationBox';
import HeaderMenuButton from '../HeaderNav/HeaderMenuButton';
import HeaderNav from '../HeaderNav/HeaderNav';

/**
 * Header for service and brand pages
 */
const HeaderService = inject(
  'windowStore',
  'navigationStore'
)(
  observer((props) => {
    const post = props.post;
    const location = props.post.location;
    const trackingParams = {
      partnerSlug: post.partner.slug,
      postType: post.post_type,
      slug: post.slug,
    };

    const getAdress = () => {
      let address = '';
      if (post && post.location) {
        if (post.location.city) {
          address += 'in ' + post.location.city + ', ';
        }
        if (post.location.address.length) {
          address += post.location.address[0];
        }
      }
      return address;
    };
    const address = getAdress();

    const store = props.windowStore;
    const sizeSuffix = store.getHeaderImageSizeSuffix();
    const image = post.post_thumbnail?.[sizeSuffix];
    const title = post.title;

    const ImgElement = () => {
      if (image && image[0]) {
        const alt = post.post_thumbnail?.meta?.alt;
        const title = post.post_thumbnail?.meta?.title;
        return (
          <Img
            key={image[0]}
            className="ida-header__image aspect-content"
            src={image[0]}
            width={image[1]}
            height={image[2]}
            alt={alt}
            title={title}
          />
        );
      } else {
        return null;
      }
    };

    // custom header partner
    const partnerFields = post.partner;
    let customHeader = partnerFields.header && partnerFields.external_id ? partnerFields.header : '';
    let CustomHeaderSingleCustom =
      customHeader &&
      React.lazy(() =>
        import(`../PartnerHeader/${partnerFields.external_id}-${partnerFields.slug}-header-single-custom`)
      );

    return customHeader ? (
      <Suspense fallback={''}>
        <CustomHeaderSingleCustom post={post} />
      </Suspense>
    ) : (
      <header className="ida-header">
        <div className={'ida-header__shape' + (props.navigationStore.navIsActive ? ' menu-is-open' : '')}>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex">
                <HeaderMenuButton />
                <h1>
                  <span className="ida-header__address">{post.partner.name}:&nbsp;</span>
                  {title}&nbsp;
                  <span className="ida-header__address"> {renderText(address)}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <HeaderNav post={post} navigation={post.navigation} />
        <div className="container ida-header__content">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-8">
              <div className="img-container">
                {ImgElement()}
                <div className="ida-header__mobile-box d-block d-lg-none">
                  {location.phone && (
                    <a
                      className={'-phone ' + trackingClassName(trackingParams, 'header-telefon')}
                      href={'tel:' + location.phone}
                    >
                      {location.phone}
                    </a>
                  )}
                </div>
              </div>
              {props.windowStore.isSmallerThan('lg') && location && (
                // mobile location box, see service.js for desktop
                <LocationBox location={location} headerTitle={title} post={post} />
              )}
            </div>
            {props.windowStore.isGreaterEqualThan('lg') && props.windowStore.isSmallerThan('xl') && location && (
              <div className="col-12 col-lg-6 col-xl-4">
                <LocationBox location={location} headerTitle={title} post={post} />
              </div>
            )}
          </div>
        </div>
      </header>
    );
  })
);
export default HeaderService;
