import React, { useEffect, useState } from 'react';
import { renderText } from '../../../helpers/api';

const BlockButton = ({ onClick, text, className, isMobile }) => {
  const [isOpen, setIsOpen] = useState(isMobile ? false : true);
  const onClickHandler = (e) => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      onClick('allowThirdPartyApp', e);
    }
  };

  useEffect(() => {
    if (!isOpen && !isMobile) {
      setIsOpen(true);
    }
  }, [isMobile, isOpen, setIsOpen]);
  const buttonClass = 'iframe-block__button' + (isOpen ? ' -open' : ' -closed');
  return (
    <div className={'iframe-block -third-party-app'}>
      <div className={'privacy-text' + (isOpen ? '' : ' -closed')}>{renderText(text)}</div>
      <button
        className={buttonClass + (className ? ' ' + className : '')}
        onClick={(e) => onClickHandler(e)}
        title="Inhalt laden"
      >
        <div className={'-text' + (isOpen ? '' : ' -closed')}>Inhalt laden</div>
      </button>
    </div>
  );
};

export default BlockButton;
