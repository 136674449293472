/**
 * Stores helper functions
 */
import { dateToDay, googleDateToStr, googleTimeToStr } from './date';
import { getConfig, getQueryStringParams } from '../helpers/api';

/**
 * format and set location data
 * @param {object} post
 * @returns object
 */

const setLocationData = (post) => {
  if (post.location) {
    const location = post.location;
    const partner = post.partner;
    let data = {
      phone: '',
      email: '',
      website: '',
      address: [],
      logo: {},
      name: '',
      city: '',
      additionalHoursText: '',
    };

    // first, try to get everything bi_data from our api
    if (location.bi_data) {
      const field = location.bi_data;

      data.phone = field.phone;
      data.email = field.email;
      data.city = field.city;
      data.name = field.name;

      if (field.additionalHoursText) {
        data.additionalHoursText = field.additionalHoursText;
      }
      data.tempClosed = 'CLOSED_TEMPORARILY' === field.openInfo?.status;
      if (field.addressLines) {
        data.address = [...field.addressLines];
        data.address.push([field.zip, field.city].join(' '));
      }
    }

    // always use the partner website (external link)
    data.website = partner?.website;

    // overwrite logo from yext, with location logo if exists or else with partner logo
    const locationLogo = location.logo;
    const partnerLogo = partner?.logo;
    const logoObj = locationLogo ? locationLogo : partnerLogo;
    if (logoObj) {
      data.logo = {
        url: logoObj.url,
        width: logoObj.width,
        height: logoObj.height,
      };
    }

    const bi = location.bi_data;
    location.formatedOpeningHours = getFormatedOpeningHours(bi);
    post.location = { ...location, ...data };
  }
};

const getFormatedOpeningHours = (bi) => {
  const formatedOpeningHours = {};
  for (const weekday in bi.hours_formatted) {
    if (bi.hours_formatted.hasOwnProperty(weekday)) {
      const value = bi.hours_formatted[weekday];
      formatedOpeningHours[weekday] = { lines: [value], isSpecial: false };
    }
  }
  if (bi.specialHours) {
    const today = dateToDay(new Date());
    const startOfPeriod = new Date(new Date().setUTCDate(today.getUTCDate() - 1));
    const endOfPeriod = new Date(new Date().setUTCDate(today.getUTCDate() + 6));

    for (let index = 0; index < bi.specialHours.length; index++) {
      const day = bi.specialHours[index];
      const startDateStr = googleDateToStr(day.startDate);
      const startDate = new Date(startDateStr);
      if (startDate < endOfPeriod && startDate > startOfPeriod) {
        let addLine = '';
        const addOnWeekday = startDate.toLocaleDateString('de-DE', { weekday: 'long' });
        if (true === day.closed) {
          // it's today?
          if (today.getTime() === startDate.getTime()) {
            addLine = 'Heute ist geschlossen';
          } else {
            addLine = 'am ' + startDate.toLocaleDateString('de-DE') + ' geschlossen';
          }
        } else {
          const openTime = googleTimeToStr(day.openTime);
          const closeTime = googleTimeToStr(day.closeTime);
          if (formatedOpeningHours[addOnWeekday].lines.length > 1) {
            const line = formatedOpeningHours[addOnWeekday].lines.pop();

            const parts = line.split(/(von|geöffnet)/);
            const addPart = `und von ${openTime} bis ${closeTime} `;
            parts.splice(parts.length - 2, 0, addPart);
            addLine = parts.join('');
          } else {
            // am 1.3.2023 von 08:00 bis 13:00 geöffnet
            addLine = `am ${startDate.toLocaleDateString('de-DE')} von ${openTime} bis ${closeTime} geöffnet`;
          }
        }
        formatedOpeningHours[addOnWeekday].lines.push(addLine);
        formatedOpeningHours[addOnWeekday].isSpecial = true;
      }
    }
  }
  return formatedOpeningHours;
};

const setPartnerData = (post) => {
  if (post.partner) {
    const partner = post.partner;
    let data = {
      phone: '',
      email: '',
      website: '',
      address: [],
      logo: {},
      name: '',
      city: '',
      additionalHoursText: '',
    };

    data.phone = partner.phone;
    data.email = partner.email;
    data.city = partner.address.partner_address_city;
    data.name = partner.name;

    let address = partner.address;
    data.address = [address.partner_address_street + ' ' + address.partner_address_street_number];
    data.address.push([address.partner_address_zip, address.partner_address_city].join(' '));
    data.address.latlng = [address.partner_address_geocoding?.lat, address.partner_address_geocoding?.lng];

    // always use the partner website (external link)
    data.website = partner?.website;

    const logoObj = partner?.logo;
    if (logoObj) {
      data.logo = {
        url: logoObj.url,
        width: logoObj.width,
        height: logoObj.height,
      };
    }

    post.partner = { ...partner, ...data };
  }
};

const setDevSubdomain = (location) => {
  const queryParams = getQueryStringParams(location.search);
  let subdomain = '';
  if (queryParams.subdomain) {
    subdomain = queryParams.subdomain;
    if ('development' === process.env?.NODE_ENV && window?.SN) {
      // save subdomain param in dev mode
      window.SN.subdomain = queryParams.subdomain;
    }
  }
  // get missing subdomain param in dev mode, so router links keep working
  // standard window.SN.subdomain comes from static file public/index.html
  subdomain = getConfig('subdomain');
  return subdomain;
};

const setDevPostType = (location) => {
  const queryParams = getQueryStringParams(location.search);
  let postType = '';
  if (queryParams.post_type) {
    postType = queryParams.post_type;
    if ('development' === process.env?.NODE_ENV && window?.SN) {
      // save subdomain param in dev mode
      window.SN.post_type = queryParams.post_type;
    }
  }
  postType = getConfig('post_type');
  return postType;
};

/**
 * used in NoMatch Copmponent
 * @param {boolean} value
 */
const setIs404 = (value) => {
  // save is_404 param
  window.SN.is_404 = true === value ? '1' : '0';
};

export { setLocationData, getFormatedOpeningHours, setDevSubdomain, setDevPostType, setPartnerData, setIs404 };
