import { createContext } from 'react';

const defaults = {
  slug: false,
  subdomain: '',
  partnerSlug: '',
  locationSlug: '',
  location: null,
  // @todo remove if all partners switched to new routing
  // indicates if location param is set, so it is not a location
  partnerPage: false,
  locationPath: '',
  navigationType: null,
};

export const RouterContext = createContext(defaults);
