import React from 'react';
import UniLink from '../../elements/UniLink/UniLink';

const FooterPortal = (props) => {
  return (
    <footer className="sn-portal__footer">
      <div className="footer-box"></div>
      <div className="footer-navi">
        <div className="footer-navi-item">
          <UniLink href={'https://sanoa.info/impressum'} target={'_blank'}>
            Impressum
          </UniLink>
        </div>
        <div className="footer-navi-item">
          <UniLink href={'https://sanoa.info/datenschutz'} target={'_blank'}>
            Datenschutz
          </UniLink>
        </div>
        <div className="footer-navi-item">
          <UniLink href={'https://sanoa.info/'} target={'_blank'}>
            Über Sanoa
          </UniLink>
        </div>
      </div>
    </footer>
  );
};

export default FooterPortal;
