import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { getConfig } from '../../../helpers/api';
import Helmet from 'react-helmet';

const GTM = observer((props) => {
  if ('production' !== getConfig('env')) {
    return null;
  }

  const { blockDataStore } = useContext(MobXProviderContext);
  const allowAnalytics = blockDataStore.allowAnalytics;
  const isActiveTracking = blockDataStore.isActiveTracking;
  const tracking_GTM_id = window.SN.tracking.mink_opt_tracking_gtm_id;

  if (!isActiveTracking || !allowAnalytics) {
    window[`ga-disable-${tracking_GTM_id}`] = true;

    return null;
  } else {
    delete window[`ga-disable-${tracking_GTM_id}`];
  }

  const config = getConfig('tracking');

  return (
    <>
      {config.mink_opt_tracking_gtm_id && (
        <Helmet>
          <script type="text/javascript">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.mink_opt_tracking_gtm_id}');`}
          </script>
        </Helmet>
      )}
    </>
  );
});

export default GTM;
