import React, { useState, useEffect } from 'react';
import SubMenu from '../SubMenu/SubMenu';
import { renderText } from '../../../helpers/api';
import { Collapse } from 'reactstrap';
import useEventListener from '@use-it/event-listener';
import { observer, inject } from 'mobx-react';
import UniLink from '../../elements/UniLink/UniLink';

const SubMenuModeratedItem = inject('navigationStore')(
  observer((props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDefaultOpen, setIsDefaultOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const element = React.createRef();

    useEffect(() => {
      if (props.navigationStore.navIsActive) {
        // menu just opened, open path to current link
        setIsOpen(isDefaultOpen);
      }
    }, [props.navigationStore.navIsActive, isDefaultOpen]);

    const handleDocumentClick = (e) => {
      // close submenu on parent or sibling click
      const container = element.current;
      if (e.target !== props.rootElement?.current && e.target !== container && !container.contains(e.target)) {
        setIsOpen(false);
      }
    };

    // see https://usehooks.com/useEventListener/
    useEventListener('click', handleDocumentClick, document, { capture: true });

    const item = props.item;
    if (!item.classes) {
      item.classes = [];
    }
    const level = props.depthLevel;
    const currentClass = 'current-menu-item';
    const handleClick = (item) => (e) => {
      const extern = item.classes.indexOf('nav-extern') !== -1;
      const toggler = -1 !== e.currentTarget.className.indexOf('toggler-');
      !extern && !toggler && props.handleClick(e, item);
    };
    const defaultOpenHandler = () => {
      setIsDefaultOpen(true);
      if (props.setParentDefaultOpen) {
        props.setParentDefaultOpen();
      }
    };
    // const isCollapse = item.children && item.children.length > 0;
    const isCollapse = item.classes.includes('toggler');

    const isCurrent = props.isCurrentUrl(item.url);
    useEffect(() => {
      if (isCurrent && props.setParentDefaultOpen) {
        props.setParentDefaultOpen();
      }
    }, [isCurrent, props]);

    return (
      <li
        ref={element}
        key={'menu_item_' + (item.menu_item_id ? item.menu_item_id : item.title)}
        className={'nav_item ' + (isCurrent ? currentClass : '') + ' ' + item.classes.join(' ')}
      >
        {isCollapse ? (
          <button
            className={'btn btn-link btn-reset ' + (isOpen ? 'open ' : '') + (isDefaultOpen ? 'current-path' : '')}
            onClick={toggle}
          >
            {renderText(item.title)}
          </button>
        ) : (
          <UniLink
            rel={item.rel ? item.rel : undefined}
            onClick={handleClick(item)}
            href={item.url}
            target={item.target ? item.target : undefined}
          >
            {renderText(item.title)}
          </UniLink>
        )}
        {item.children && (
          <>
            {isCollapse ? (
              <Collapse className="sn-collapse" timeout={0} isOpen={isOpen}>
                <SubMenu
                  items={item.children}
                  depthLevel={level + 1}
                  handleClick={props.handleClick}
                  isCurrentUrl={props.isCurrentUrl}
                  currentPath={props.currentPath}
                  setParentDefaultOpen={defaultOpenHandler}
                  rootElement={props.rootElement}
                />
              </Collapse>
            ) : (
              <SubMenu
                items={item.children}
                depthLevel={level + 1}
                handleClick={props.handleClick}
                isCurrentUrl={props.isCurrentUrl}
                currentPath={props.currentPath}
                setParentDefaultOpen={defaultOpenHandler}
                rootElement={props.rootElement}
              />
            )}
          </>
        )}
      </li>
    );
  })
);

export default SubMenuModeratedItem;
