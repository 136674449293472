import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { getConfig, renderText } from '../../../helpers/api';
import { Helmet } from 'react-helmet';

const ACFPrescriptionUpload = inject('formStore')(
  observer((props) => {
    let wpcf7AssetsCSS = [];
    const formStore = props.formStore;
    const wpcf7_assets = getConfig('wpcf7_assets');

    if (wpcf7_assets && wpcf7_assets.css) {
      wpcf7AssetsCSS = wpcf7_assets.css;
    }

    useEffect(() => {
      formStore.onReadyForm();

      if (formStore.allowLoadScript) {
        formStore.loadFormScripts();
      }
    }, [formStore]);

    return (
      <>
        {formStore.loadStyles ? (
          <Helmet>
            {wpcf7AssetsCSS.map((style, i) => {
              return <link key={i} rel="stylesheet" href={style}></link>;
            })}
          </Helmet>
        ) : null}
        {renderText(props.content.mink_fc_prescription_form_post.post_content)}
      </>
    );
  })
);

export default ACFPrescriptionUpload;
